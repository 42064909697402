import '../../css/styles.css';


import { useState, useEffect, useCallback } from 'react';
import {
    AccountMenu, LoginOverlay, TwoSwitch, decodeJWT, getURLSearchParam
}                                                   from 'iprs-react-library';
//import Counter                                  from './components/Counter';
//import AppType                                  from './components/AppType';

import AeromedReferralAppPages  from './AeromedReferralAppPages';
import GetAeromedClient         from './GetAeromedClient';
import Grid from '../CaseUpdatePage/ObservationFormGrid/Grid';
import ObservationFieldDefinitions from '../CaseUpdatePage/ObservationFormGrid/ObservationFieldDefinitions';
import GridDisplay from '../CaseUpdatePage/ObservationFormGrid/GridDisplay';


const clientID = process.env.REACT_APP_ClientID;



//const testingToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJJUFJTX0tleV9JbmRleCI6IjA4MzYzMjEwRjEiLCJPcmdJRCI6IjEiLCJVc2VySUQiOiIyMTQ3NzMiLCJyb2xlIjoiRGlhbW9uZCBTeXN0ZW0gQWRtaW4sRGlhcnkgVXNlcixEaWFyeSBSb3RhLERpYXJ5IE1hbmFnZXIsVXNlcixDbGluaWNpYW4sUmVmZXJyZXIiLCJyb2xlcyI6IkRpYW1vbmQgU3lzdGVtIEFkbWluLERpYXJ5IFVzZXIsRGlhcnkgUm90YSxEaWFyeSBNYW5hZ2VyLFVzZXIsQ2xpbmljaWFuLFJlZmVycmVyIiwiaXNzIjoiaUNvcmUiLCJhdWQiOiJodHRwczovL3dlYmFwaS5pcHJzYXBwcy5jb20iLCJleHAiOjE2NjU1MjQ1MjIsIm5iZiI6MTY2NTQ5NTcyMn0._4mfBofE2zpwhLFzcriWgYxl_0dNthvMjXIMytNNr3g';

const storedToken = localStorage.getItem("Token");




const AeromedReferralApp = ({ api, client }) => {
    
    const clientSelected = null != client;
    let title;
    if (!clientSelected) {
        title = 'Aeromed Case Update - Choose a site';
    } else if ('holding-rooms' == client) {
        title = 'Aeromed Case Update - Holding Rooms';
    } else {
        title = 'Aeromed Case Update - Serco';
    }

    const [loggedIn, setLoggedIn] = useState(false);

    const [err, setErr] = useState('');

    const handleLoginEvent =
        useCallback(action => {
            const isLoggedIn = 'Logged in' === action;
            setLoggedIn(isLoggedIn);
            localStorage.setItem("Token", api.getToken());
        }, 
        [setLoggedIn]);

    useEffect(() => { document.title = title }, [client]);

    useEffect(() => {
        const urlToken     = getURLSearchParam('Token');
        api.validateLoggedIn(storedToken) // check existing stored
            .then(token =>  { setLoggedIn(true); localStorage.setItem("Token", api.getToken()); } )
            .catch(e => api.validateLoggedIn(urlToken))
            .then(()=>setLoggedIn(true))
            .catch(e=>{setErr(e.Message); localStorage.setItem("Token", null); });
        }, []);

    return <div className="App">
        <div className="container">
            <header className="topHeader main__top">
                <div className="main__top-logo">
                    <img src="images/iprsaeromed-logoclrwhite-250.png" alt="IPRS Health" />
                </div>
                <h1 className="main__top-title">
                    Aeromed Case Additional Data Collection
                </h1>
                <AccountMenu api={api} handleLoginEvent={handleLoginEvent} />
            </header>
        </div>
        <div className="container container-sm">
            <main className="main">

                <TwoSwitch test={clientSelected} >
                    <TwoSwitch test={!loggedIn} >

                        <div className="container typography login_container" >
                            <LoginOverlay handleLoginEvent={handleLoginEvent} api={api} />
                        </div>

                        <section className="main__section">
                            <AeromedReferralAppPages api={api} ClientID={clientID} />
                        </section>
                    </TwoSwitch>
                    <>
                    <h1>{title}</h1>
                    <p>
                        This site provides data input forms for </p>
                        <ul>
                            <li><a href='/holding-rooms'    >Holding Rooms</a></li>
                            <li><a href='/serco'            >Serco</a></li>
                         </ul>
                    </>
                </TwoSwitch>
            </main>
        </div>
    </div>
}

export default AeromedReferralApp;
