import GridRow from './GridRow';


const getRowForFieldDefinition = (fieldDefinition, formFieldDataColumns) => {
    const fieldName = fieldDefinition.name;
    const rowDataArray = formFieldDataColumns.map(column => column?.[fieldName]);
    const result = { fieldDefinition, rowDataArray };
    return result;
};

/**
 * This was originally to be a component to display an editable grid of form fields.
 * In the end it just displays the grid data but does not allow editing.
 * @param {*} param0 
 * @returns 
 */
const GridDisplay = ({fieldDefinitions, formFieldData}) => {

    if (!Array.isArray(fieldDefinitions)) {
        throw new Error('Grid must have an array of field definitions');
    }

    const rowsData = 
        fieldDefinitions.map( fieldDefinition => getRowForFieldDefinition( fieldDefinition, formFieldData ) )

    return rowsData.map( rowData => <GridRow rowData={rowData} /> );
}


export default GridDisplay;