import DeployedFromLocation from './DeployedFromLocation';
import EthincityOptionDefinitions from './EthincityOptionDefinitions';
import NationalityOptionDefinitions from './NationalityOptionDefinitions';
import medicationOptionDefinitions from './MediacationOptionDefinitions';

const fieldDefinitions = [
    {
        "name": "ClientId",
        "value": 155409, 
        "controlType": "Hidden"
    },
    {
        "name": "ServiceActivityId",
        "value": 2008, 
        "controlType": "Hidden"
    },
    {
        "name": "HealthcareProfessionName",
        "value": 'N/A', 
        "controlType": "Hidden"
    },
    {
        "name": "CustodyNumber",
        "value": 'No Custody Number', 
        "controlType": "Hidden"
    },
    {
        "name": "ConsentToShareAndCare",
        "value": "N/A", 
        "controlType": "Hidden"
    },
    {
        "name": "Forename",
        "text": "Forename:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Surname",
        "text": "Surname:",
        "enabled": "false",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "DOB",
        "text": "Date of Birth:",
        "enabled": "true",
        "required": "True",
        "controlType": "datetextbox",
        "pivotYAhead": 0,
        "maxDaysAhead": 0,
        "defaultValue": "",
    },
    {
        "name": "Gender",
        "text": "Gender:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": [
            {
                "Text": "Male",
                "Value": "M"
            },
            {
                "Text": "Female",
                "Value": "F"
            },
            {
                "Text": "Unknown",
                "Value": "U"
            }
        ]
    },
    {
        "name": "Nationality",
        "text": "Nationality:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": NationalityOptionDefinitions
    },
    {
        "name": "Ethicity",
        "text": "Ethicity:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": EthincityOptionDefinitions,
        "otherOption": "Other Ethicity"
    },
    {
        "name": "PT Reference number",
        "text": "PT Reference number",
        "controlType": "textbox",
        "required": true
    },
    {
        "name": "Site",
        "text": "Location",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": [
            {
              "Text": "AMERSHAM LAW COURTS",
              "Value": "61198"
            },
            {
              "Text": "AYLESBURY CC",
              "Value": "60966"
            },
            {
              "Text": "BARKINGSIDE MC",
              "Value": "60967"
            },
            {
              "Text": "BARNSTAPLE COMBINED",
              "Value": "60968"
            },
            {
              "Text": "BASILDON CC",
              "Value": "60969"
            },
            {
              "Text": "BASILDON MC",
              "Value": "60970"
            },
            {
              "Text": "BASINGSTOKE MC",
              "Value": "60971"
            },
            {
              "Text": "BATH MC",
              "Value": "60972"
            },
            {
              "Text": "BEXLEYHEATH MC",
              "Value": "60973"
            },
            {
              "Text": "BLACKFRIARS CC",
              "Value": "60974"
            },
            {
              "Text": "BODMIN MC",
              "Value": "60977"
            },
            {
              "Text": "BOURNEMOUTH CC",
              "Value": "60978"
            },
            {
              "Text": "BRIGHTON COMBINED",
              "Value": "60979"
            },
            {
              "Text": "BRISTOL CC",
              "Value": "60980"
            },
            {
              "Text": "BRISTOL MC",
              "Value": "60981"
            },
            {
              "Text": "BROMLEY MC",
              "Value": "60982"
            },
            {
              "Text": "CAMBERWELL GREEN MC",
              "Value": "60983"
            },
            {
              "Text": "CAMBRIDGE CC",
              "Value": "60984"
            },
            {
              "Text": "CAMBRIDGE MC",
              "Value": "60985"
            },
            {
              "Text": "CANTERBURY CC",
              "Value": "60986"
            },
            {
              "Text": "CANTERBURY MC",
              "Value": "60987"
            },
            {
              "Text": "CENTRAL CRIMINAL COURT",
              "Value": "60988"
            },
            {
              "Text": "CHATHAM MC",
              "Value": "60989"
            },
            {
              "Text": "CHELMSFORD CC",
              "Value": "60990"
            },
            {
              "Text": "CHELMSFORD MC",
              "Value": "60991"
            },
            {
              "Text": "CHELTENHAM MC",
              "Value": "60992"
            },
            {
              "Text": "CITY OF LONDON MC",
              "Value": "60993"
            },
            {
              "Text": "COLCHESTER MC",
              "Value": "60994"
            },
            {
              "Text": "COURT OF APPEAL",
              "Value": "60995"
            },
            {
              "Text": "CRAWLEY MC",
              "Value": "60996"
            },
            {
              "Text": "CROYDON CC",
              "Value": "60997"
            },
            {
              "Text": "CROYDON MC",
              "Value": "60998"
            },
            {
              "Text": "EALING MC",
              "Value": "60999"
            },
            {
              "Text": "EXETER & WONFORD MC",
              "Value": "61000"
            },
            {
              "Text": "EXETER CC",
              "Value": "61001"
            },
            {
              "Text": "FOLKESTONE MC",
              "Value": "61002"
            },
            {
              "Text": "GLOUCESTER CC",
              "Value": "61003"
            },
            {
              "Text": "GREAT YARMOUTH MC",
              "Value": "61004"
            },
            {
              "Text": "GUILDFORD CC",
              "Value": "61005"
            },
            {
              "Text": "GUILDFORD MC",
              "Value": "61006"
            },
            {
              "Text": "HARROW CC",
              "Value": "61007"
            },
            {
              "Text": "HASTINGS MC",
              "Value": "61008"
            },
            {
              "Text": "HATFIELD RC",
              "Value": "61009"
            },
            {
              "Text": "HENDON MC",
              "Value": "61010"
            },
            {
              "Text": "HIGH WYCOMBE MC",
              "Value": "61011"
            },
            {
              "Text": "HIGHBURY CORNER MC",
              "Value": "61012"
            },
            {
              "Text": "HOVE CC / TRIAL CENTRE",
              "Value": "61013"
            },
            {
              "Text": "HUNTINGDON LAW COURTS",
              "Value": "61014"
            },
            {
              "Text": "INNER LONDON CC",
              "Value": "61015"
            },
            {
              "Text": "IPSWICH CC",
              "Value": "61016"
            },
            {
              "Text": "IPSWICH MC",
              "Value": "61017"
            },
            {
              "Text": "ISLE OF WIGHT COMBINED COURT",
              "Value": "61197"
            },
            {
              "Text": "ISLEWORTH CC",
              "Value": "61018"
            },
            {
              "Text": "KINGS LYNN MC",
              "Value": "61019"
            },
            {
              "Text": "KINGSTON CC",
              "Value": "61020"
            },
            {
              "Text": "LEWES CC",
              "Value": "61021"
            },
            {
              "Text": "LUTON CC",
              "Value": "61022"
            },
            {
              "Text": "LUTON MC",
              "Value": "61023"
            },
            {
              "Text": "MAIDSTONE CC",
              "Value": "61024"
            },
            {
              "Text": "MAIDSTONE MC",
              "Value": "61025"
            },
            {
              "Text": "MARGATE MC",
              "Value": "61026"
            },
            {
              "Text": "MEDWAY MC",
              "Value": "61027"
            },
            {
              "Text": "MILTON KEYNES MC",
              "Value": "61028"
            },
            {
              "Text": "NEWPORT (IOW)",
              "Value": "61029"
            },
            {
              "Text": "NEWTON ABBOT MC",
              "Value": "61030"
            },
            {
              "Text": "NORTH SOMERSET MC",
              "Value": "61031"
            },
            {
              "Text": "NORWICH CC",
              "Value": "61032"
            },
            {
              "Text": "NORWICH MC",
              "Value": "61033"
            },
            {
              "Text": "OXFORD CC",
              "Value": "61034"
            },
            {
              "Text": "OXFORD MC",
              "Value": "61035"
            },
            {
              "Text": "PETERBOROUGH CC",
              "Value": "61036"
            },
            {
              "Text": "PETERBOROUGH MC",
              "Value": "61037"
            },
            {
              "Text": "PLYMOUTH CC",
              "Value": "61038"
            },
            {
              "Text": "PLYMOUTH MC",
              "Value": "61039"
            },
            {
              "Text": "POOLE MC",
              "Value": "61040"
            },
            {
              "Text": "PORTSMOUTH CC",
              "Value": "61041"
            },
            {
              "Text": "PORTSMOUTH MC",
              "Value": "61042"
            },
            {
              "Text": "READING CC",
              "Value": "61043"
            },
            {
              "Text": "READING MC",
              "Value": "61044"
            },
            {
              "Text": "ROMFORD MC",
              "Value": "61045"
            },
            {
              "Text": "ROYAL COURTS OF JUSTICE",
              "Value": "61342"
            },
            {
              "Text": "SALISBURY COMBINED",
              "Value": "61046"
            },
            {
              "Text": "SEVENOAKS MC",
              "Value": "61047"
            },
            {
              "Text": "SLOUGH MC",
              "Value": "61048"
            },
            {
              "Text": "SNARESBROOK CC & ANNEX",
              "Value": "61049"
            },
            {
              "Text": "SOUTHAMPTON CC",
              "Value": "61051"
            },
            {
              "Text": "SOUTHAMPTON MC",
              "Value": "61052"
            },
            {
              "Text": "SOUTHEND COMBINED COURT",
              "Value": "61050"
            },
            {
              "Text": "SOUTHWARK CC",
              "Value": "61053"
            },
            {
              "Text": "ST ALBANS CC",
              "Value": "61054"
            },
            {
              "Text": "ST ALBANS MC",
              "Value": "61055"
            },
            {
              "Text": "STAINES MC",
              "Value": "61056"
            },
            {
              "Text": "STEVENAGE MC",
              "Value": "61057"
            },
            {
              "Text": "STRATFORD MC",
              "Value": "61058"
            },
            {
              "Text": "SWINDON CC",
              "Value": "61059"
            },
            {
              "Text": "SWINDON MC",
              "Value": "61060"
            },
            {
              "Text": "TAUNTON CC",
              "Value": "61061"
            },
            {
              "Text": "TAUNTON MC",
              "Value": "61062"
            },
            {
              "Text": "THAMES MC",
              "Value": "61063"
            },
            {
              "Text": "TRURO CC",
              "Value": "61064"
            },
            {
              "Text": "TRURO MC",
              "Value": "61065"
            },
            {
              "Text": "UXBRIDGE MC",
              "Value": "61066"
            },
            {
              "Text": "WESTMINSTER MC",
              "Value": "61067"
            },
            {
              "Text": "WEYMOUTH MC",
              "Value": "61068"
            },
            {
              "Text": "WILLESDON MC",
              "Value": "61069"
            },
            {
              "Text": "WIMBLEDON MC",
              "Value": "61070"
            },
            {
              "Text": "WINCHESTER CC",
              "Value": "61071"
            },
            {
              "Text": "WOODGREEN CC",
              "Value": "61072"
            },
            {
              "Text": "WOOLWICH CC",
              "Value": "61073"
            },
            {
              "Text": "WORTHING MC",
              "Value": "61074"
            },
            {
              "Text": "YEOVIL MC",
              "Value": "61075"
            }
          ]
    },
    {
        "name": "Deployed from",
        "text": "Deployed from",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": DeployedFromLocation,
        "otherOption": "Other (Please specify)",
    },
        {
          "name": "CallDate",
          "text": "Call Date:",
          "enabled": "true",
          "required": "True",
          "controlType": "datetextbox",
          "pivotYAhead": 100,
          "maxDaysPrevious": 365,
          "maxDaysAhead": 0,
          "defaultValue": "today",
        },
        {
          "name": "CallTime",
          "text": "Call Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallDate"
        },
        {
          "name": "MobileTime",
          "text": "Mobile Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "referralInputValidate": "answers.CallTime < this",
          "referralInputError": "Mobile Time must be after Call Time",
          "RelativeTo": "CallTime"
        },
        {
          "name": "OnSceneTime",
          "text": "On Scene Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time",
          "RelativeTo": "CallTime"
        },
        {
          "name": "PatientContactTime",
          "text": "Patient Contact Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time",
          "RelativeTo": "CallTime"
        },

        {
          "name": "ClearTime",
          "controlType": "hidden",
          "value": '1900-01-01T01:01'
        },
        {
          "name": "MentalCapacity",
          "controlType": "hidden",
          "value": 0
        },
        {
        "name": "MedicationAdministered",
        "text": "Medication Administered:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "MedicationName1",
        "text": "Type of Medication Administered 1:",
        "enabled": "true",
        "required": "True",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationName2",
        "text": "Type of Medication Administered 2:",
        "enabled": "true",
        "required": "False",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationName3",
        "text": "Type of Medication Administered 3:",
        "enabled": "true",
        "required": "False",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationPatientsOwn",
        "text": "Medication Used Patients Own?:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "MedicationPatientsNotOwnReason",
        "text": "Reason why Patients own medication not used:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true' && $scope.Answers.MedicationPatientsOwn == 'false'",
        "Options": [
            {
                "Text": "Not Available",
                "Value": "Not Available"
            },
            {
                "Text": "Not Required",
                "Value": "Not Required"
            },
            {
                "Text": "Not Suitable",
                "Value": "Not Suitable"
            },
            {
                "Text": "Not Recorded/ Documented in patients notes",
                "Value": "Not Recorded/ Documented in patients notes"
            },
            {
                "Text": "Not Recognised",
                "Value": "Not Recognised"
            }
        ]
    },
    {
        "name": "MedicationFromStock",
        "text": "Medication used from Stock?:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true' && $scope.Answers.MedicationPatientsOwn == 'false'",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
        {
          "name": "OtherComments",
          "text": "Additional Comments:",
          "enabled": "true",
          "required": "False",
          "mandatory": "false",
          "controlType": "TextBox",
          "width": "300",
          "rows": "5"
        }
];

export default fieldDefinitions;