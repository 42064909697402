

const HoldingRoomsPatientDetailsForm = [
    
    {
        "name": "Nationality",
        "text": "Nationality:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": [
            {
                "Text": "UK",
                "Value": "uk"
            }
        ]
    },
    {
        "name": "Deployed from",
        "text": "Deployed from",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": [
            {
                "Text": "Bristol",
                "Value": "bristol"
            },
            {
                "Text": "Croydon",
                "Value": "croydon"
            },
            {
                "Text": "Eastleigh",
                "Value": "eastleigh"
            },
            {
                "Text": "Exeter",
                "Value": "exeter"
            },
            {
                "Text": "Gatwick",
                "Value": "gatwick"
            },
            {
                "Text": "Greeford",
                "Value": "greenford"
            },
            {
                "Text": "New Southgrate",
                "Value": "new southgate"
            },
            {
                "Text": "Stansted",
                "Value": "Stansted"
            }
        ]
    },
        {
          "name": "CallDate",
          "text": "Call Date:",
          "enabled": "true",
          "required": "True",
          "controlType": "datetextbox",
          "pivotYAhead": 100,
          "maxDaysPrevious": 365,
          "maxDaysAhead": 0,
          "defaultValue": "today",
        },
        {
          "name": "CallTime",
          "text": "Call Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallDate"
        },
        {
          "name": "MobileTime",
          "text": "Mobile Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallTime",
          "referralInputValidate": "answers.CallTime < this",
          "referralInputError": "Mobile Time must be after Call Time"
        },
        {
          "name": "OnSceneTime",
          "text": "On Scene Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo":     "MobileTime",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time"
        },
        {
          "name": "PatientContactTime",
          "text": "Patient Contact Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "OnSceneTime",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time"
        },
        {
          "name": "ClearTimeUpdate",
          "text": "Clear Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo":     "PatientContactTime",
          "referralInputValidate": "answers.OnSceneTime < this",
          "referralInputError": "Clear Time must be after On Scene Time"
        },
        {
          "name": "MentalCapacity",
          "text": "Mental Capacity:",
          "enabled": "true",
          "required": "True",
          "mandatory": "false",
          "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "true"
            },
            {
              "Text": "No",
              "Value": "false"
            }
          ]
        },
        {
          "name": "OtherComments",
          "text": "Additional Comments:",
          "enabled": "true",
          "required": "False",
          "mandatory": "false",
          "controlType": "TextBox",
          "width": "300",
          "rows": "5"
        }
];

export default HoldingRoomsPatientDetailsForm;
