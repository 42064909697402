
const NationalityOptionUpdateDefinitions = [
            {
                "Text": "Afghan"
                
            },
            {
                "Text": "Albanian"
                
            },
            {
                "Text": "Algerian"
                
            },
            {
                "Text": "American"
                
            },
            {
                "Text": "Andorran"
                
            },
            {
                "Text": "Angolan"
                
            },
            {
                "Text": "Anguillan"
                
            },
            {
                "Text": "Argentine"
                
            },
            {
                "Text": "Armenian"
                
            },
            {
                "Text": "Australian"
                
            },
            {
                "Text": "Austrian"
                
            },
            {
                "Text": "Azerbaijani"
                
            },
            {
                "Text": "Bahamian"
                
            },
            {
                "Text": "Bahraini"
                
            },
            {
                "Text": "Bangladeshi"
                
            },
            {
                "Text": "Barbadian"
                
            },
            {
                "Text": "Belarusian"
                
            },
            {
                "Text": "Belgian"
                
            },
            {
                "Text": "Belizean"
                
            },
            {
                "Text": "Beninese"
                
            },
            {
                "Text": "Bermudian"
                
            },
            {
                "Text": "Bhutanese"
                
            },
            {
                "Text": "Bolivian"
                
            },
            {
                "Text": "Botswanan"
                
            },
            {
                "Text": "Brazilian"
                
            },
            {
                "Text": "British"
                
            },
            {
                "Text": "British Virgin Islander"
                
            },
            {
                "Text": "Bruneian"
                
            },
            {
                "Text": "Bulgarian"
                
            },
            {
                "Text": "Burkinan"
                
            },
            {
                "Text": "Burmese"
                
            },
            {
                "Text": "Burundian"
                
            },
            {
                "Text": "Cambodian"
                
            },
            {
                "Text": "Cameroonian"
                
            },
            {
                "Text": "Canadian"
                
            },
            {
                "Text": "Cape Verdean"
                
            },
            {
                "Text": "Cayman Islander"
                
            },
            {
                "Text": "Central African"
                
            },
            {
                "Text": "Chadian"
                
            },
            {
                "Text": "Chilean"
                
            },
            {
                "Text": "Chinese"
                
            },
            {
                "Text": "Citizen of Antigua and Barbuda"
                
            },
            {
                "Text": "Citizen of Bosnia and Herzegovina"
                
            },
            {
                "Text": "Citizen of Guinea-Bissau"
                
            },
            {
                "Text": "Citizen of Kiribati"
                
            },
            {
                "Text": "Citizen of Seychelles"
                
            },
            {
                "Text": "Citizen of the Dominican Republic"
                
            },
            {
                "Text": "Citizen of Vanuatu"
                
            },
            {
                "Text": "Colombian"
                
            },
            {
                "Text": "Comoran"
                
            },
            {
                "Text": "Congolese (Congo)"
                
            },
            {
                "Text": "Congolese (DRC)"
                
            },
            {
                "Text": "Cook Islander"
                
            },
            {
                "Text": "Costa Rican"
                
            },
            {
                "Text": "Croatian"
                
            },
            {
                "Text": "Cuban"
                
            },
            {
                "Text": "Cymraes"
                
            },
            {
                "Text": "Cymro"
                
            },
            {
                "Text": "Cypriot"
                
            },
            {
                "Text": "Czech"
                
            },
            {
                "Text": "Danish"
                
            },
            {
                "Text": "Djiboutian"
                
            },
            {
                "Text": "Dominican"
                
            },
            {
                "Text": "Dutch"
                
            },
            {
                "Text": "East Timorese"
                
            },
            {
                "Text": "Ecuadorean"
                
            },
            {
                "Text": "Egyptian"
                
            },
            {
                "Text": "Emirati"
                
            },
            {
                "Text": "English"
                
            },
            {
                "Text": "Equatorial Guinean"
                
            },
            {
                "Text": "Eritrean"
                
            },
            {
                "Text": "Estonian"
                
            },
            {
                "Text": "Ethiopian"
                
            },
            {
                "Text": "Faroese"
                
            },
            {
                "Text": "Fijian"
                
            },
            {
                "Text": "Filipino"
                
            },
            {
                "Text": "Finnish"
                
            },
            {
                "Text": "French"
                
            },
            {
                "Text": "Gabonese"
                
            },
            {
                "Text": "Gambian"
                
            },
            {
                "Text": "Georgian"
                
            },
            {
                "Text": "German"
                
            },
            {
                "Text": "Ghanaian"
                
            },
            {
                "Text": "Gibraltarian"
                
            },
            {
                "Text": "Greek"
                
            },
            {
                "Text": "Greenlandic"
                
            },
            {
                "Text": "Grenadian"
                
            },
            {
                "Text": "Guamanian"
                
            },
            {
                "Text": "Guatemalan"
                
            },
            {
                "Text": "Guinean"
                
            },
            {
                "Text": "Guyanese"
                
            },
            {
                "Text": "Haitian"
                
            },
            {
                "Text": "Honduran"
                
            },
            {
                "Text": "Hong Konger"
                
            },
            {
                "Text": "Hungarian"
                
            },
            {
                "Text": "Icelandic"
                
            },
            {
                "Text": "Icelandic"
                
            },
            {
                "Text": "Indian"
                
            },
            {
                "Text": "Indian"
                
            },
            {
                "Text": "Indonesian"
                
            },
            {
                "Text": "Indonesian"
                
            },
            {
                "Text": "Iranian"
                
            },
            {
                "Text": "Iranian"
                
            },
            {
                "Text": "Iraqi"
                
            },
            {
                "Text": "Irish"
                
            },
            {
                "Text": "Israeli"
                
            },
            {
                "Text": "Italian"
                
            },
            {
                "Text": "Ivorian"
                
            },
            {
                "Text": "Jamaican"
                
            },
            {
                "Text": "Japanese"
                
            },
            {
                "Text": "Jordanian"
                
            },
            {
                "Text": "Kazakh"
                
            },
            {
                "Text": "Kenyan"
                
            },
            {
                "Text": "Kittitian"
                
            },
            {
                "Text": "Kosovan"
                
            },
            {
                "Text": "Kuwaiti"
                
            },
            {
                "Text": "Kyrgyz"
                
            },
            {
                "Text": "Lao"
                
            },
            {
                "Text": "Latvian"
                
            },
            {
                "Text": "Lebanese"
                
            },
            {
                "Text": "Liberian"
                
            },
            {
                "Text": "Libyan"
                
            },
            {
                "Text": "Liechtenstein citizen"
                
            },
            {
                "Text": "Lithuanian"
                
            },
            {
                "Text": "Luxembourger"
                
            },
            {
                "Text": "Macanese"
                
            },
            {
                "Text": "Macedonian"
                
            },
            {
                "Text": "Malagasy"
                
            },
            {
                "Text": "Malawian"
                
            },
            {
                "Text": "Malaysian"
                
            },
            {
                "Text": "Maldivian"
                
            },
            {
                "Text": "Malian"
                
            },
            {
                "Text": "Maltese"
                
            },
            {
                "Text": "Marshallese"
                
            },
            {
                "Text": "Martiniquais"
                
            },
            {
                "Text": "Mauritanian"
                
            },
            {
                "Text": "Mauritian"
                
            },
            {
                "Text": "Mexican"
                
            },
            {
                "Text": "Micronesian"
                
            },
            {
                "Text": "Moldovan"
                
            },
            {
                "Text": "Monegasque"
                
            },
            {
                "Text": "Mongolian"
                
            },
            {
                "Text": "Montenegrin"
                
            },
            {
                "Text": "Montserratian"
                
            },
            {
                "Text": "Moroccan"
                
            },
            {
                "Text": "Mosotho"
                
            },
            {
                "Text": "Mozambican"
                
            },
            {
                "Text": "Namibian"
                
            },
            {
                "Text": "Nauruan"
                
            },
            {
                "Text": "Nepalese"
                
            },
            {
                "Text": "New Zealander"
                
            },
            {
                "Text": "Nicaraguan"
                
            },
            {
                "Text": "Nigerian"
                
            },
            {
                "Text": "Nigerien"
                
            },
            {
                "Text": "Niuean"
                
            },
            {
                "Text": "North Korean"
                
            },
            {
                "Text": "Northern Irish"
                
            },
            {
                "Text": "Norwegian"
                
            },
            {
                "Text": "Omani"
                
            },
            {
                "Text": "Pakistani"
                
            },
            {
                "Text": "Palauan"
                
            },
            {
                "Text": "Palestinian"
                
            },
            {
                "Text": "Panamanian"
                
            },
            {
                "Text": "Papua New Guinean"
                
            },
            {
                "Text": "Paraguayan"
                
            },
            {
                "Text": "Peruvian"
                
            },
            {
                "Text": "Pitcairn Islander"
                
            },
            {
                "Text": "Polish"
                
            },
            {
                "Text": "Portuguese"
                
            },
            {
                "Text": "Prydeinig"
                
            },
            {
                "Text": "Puerto Rican"
                
            },
            {
                "Text": "Qatari"
                
            },
            {
                "Text": "Romanian"
                
            },
            {
                "Text": "Russian"
                
            },
            {
                "Text": "Rwandan"
                
            },
            {
                "Text": "Salvadorean"
                
            },
            {
                "Text": "Sammarinese"
                
            },
            {
                "Text": "Samoan"
                
            },
            {
                "Text": "Sao Tomean"
                
            },
            {
                "Text": "Saudi Arabian"
                
            },
            {
                "Text": "Scottish"
                
            },
            {
                "Text": "Senegalese"
                
            },
            {
                "Text": "Serbian"
                
            },
            {
                "Text": "Sierra Leonean"
                
            },
            {
                "Text": "Singaporean"
                
            },
            {
                "Text": "Slovak"
                
            },
            {
                "Text": "Slovenian"
                
            },
            {
                "Text": "Solomon Islander"
                
            },
            {
                "Text": "Somali"
                
            },
            {
                "Text": "South African"
                
            },
            {
                "Text": "South Korean"
                
            },
            {
                "Text": "South Sudanese"
                
            },
            {
                "Text": "Spanish"
                
            },
            {
                "Text": "Sri Lankan"
                
            },
            {
                "Text": "St Helenian"
                
            },
            {
                "Text": "St Lucian"
                
            },
            {
                "Text": "Stateless"
                
            },
            {
                "Text": "Sudanese"
                
            },
            {
                "Text": "Surinamese"
                
            },
            {
                "Text": "Swazi"
                
            },
            {
                "Text": "Swedish"
                
            },
            {
                "Text": "Swiss"
                
            },
            {
                "Text": "Syrian"
                
            },
            {
                "Text": "Taiwanese"
                
            },
            {
                "Text": "Tajik"
                
            },
            {
                "Text": "Tanzanian"
                
            },
            {
                "Text": "Thai"
                
            },
            {
                "Text": "Togolese"
                
            },
            {
                "Text": "Tongan"
                
            },
            {
                "Text": "Trinidadian"
                
            },
            {
                "Text": "Tristanian"
                
            },
            {
                "Text": "Tunisian"
                
            },
            {
                "Text": "Turkish"
                
            },
            {
                "Text": "Turkmen"
                
            },
            {
                "Text": "Turks and Caicos Islander"
                
            },
            {
                "Text": "Tuvaluan"
                
            },
            {
                "Text": "Ugandan"
                
            },
            {
                "Text": "Ukrainian"
                
            },
            {
                "Text": "Uruguayan"
                
            },
            {
                "Text": "Uzbek"
                
            },
            {
                "Text": "Vatican citizen"
                
            },
            {
                "Text": "Venezuelan"
                
            },
            {
                "Text": "Vietnamese"
                
            },
            {
                "Text": "Vincentian"
                
            },
            {
                "Text": "Wallisian"
                
            },
            {
                "Text": "Welsh"
                
            },
            {
                "Text": "Yemeni"
                
            },
            {
                "Text": "Zambian"
                
            },
            {
                "Text": "Zimbabwean"
                
            }
        ];

export default NationalityOptionUpdateDefinitions;