import React,{ useState }               from "react";
import sercoFieldDefinitions            from './AeromedSercoReferralFormFieldDefinitions';
import holdingRoomsFieldDefinitions     from './AeromedHoldingRoomsReferralFormFieldDefinitions';

import {ButtonHolder, LoadingIndicator}                   from 'iprs-react-library';
import GetAeromedClient                 from '../AeromedReferralApp/GetAeromedClient';
import { ValidatedForm }                from 'iprs-react-library';
import TimeTextBox from "../TMPFieldOverrides/TimeTextBox";

const clientID = process.env.REACT_APP_ClientID;

const client            = GetAeromedClient();
const isHoldingRooms = 'holding-rooms' === client;
let title, fieldDefinitions;
if (isHoldingRooms) {
    title = 'Aeromed Create Case - Holding Rooms';
    fieldDefinitions = holdingRoomsFieldDefinitions;
} else {
    title = 'Aeromed Create Case - Serco';
    fieldDefinitions = sercoFieldDefinitions;
}

// WARNING: One of the fields differes between holding rooms and serco 
// (ClearTime vs PatientContactTime)

const CreateCasePage = ({ api, setcaseToWorkWith, cancelEdit }) => {
    
    if (!cancelEdit) {
        throw new Error('cancelEdit must be a function');
    }

    const [formFieldData, setFormFieldData]         = useState({});
    
    const [errorMessage, setErrorMessage]           = useState('');
    
    const [dataIsValid, setDataIsValid]             = useState(false);

    const [highlightValidation, setHighlightValidation]
                                                    = useState(false);
    const [busy, setBusy]                           = useState(false);

    const onSubmit = () => {

        const {datesValid, datesInOrder} = datesAndTimesValid(formFieldData);

        if(dataIsValid && datesValid && datesInOrder){

            setBusy(true);
            setErrorMessage('');
            const formFieldDataCopy = {...formFieldData, ClientId: clientID}
            let caseReferrenceCreated;
             api.transact('SaveAeromedReferralForm', { 'Data': formFieldDataCopy, 
                 AttachUserToCaseAsCustodyMedic: true })
            .then(r => r.apiResult)
            .then(r => {
                const caseReferrenceReceived = r.CaseRef;
                if (typeof caseReferrenceReceived !== 'string' || 0 === caseReferrenceReceived.trim().length) {
                    throw new Error('caseReferrence must be a valid string');
                }
                caseReferrenceCreated = caseReferrenceReceived;
                return caseReferrenceCreated;
            })
            .then(() => api.transact('ClinicianAssignedCases', {} ))
            .then(response => response.apiResult)
            .then(clinicianAssignedCases => clinicianAssignedCases.filter( aCase => aCase.CaseReferrence === caseReferrenceCreated) )
            .then( createdCases => { 
                setBusy(false);
                if (1 !== createdCases.length) {
                    throw new Error("Case Created but not assigned to you");
                }
                setcaseToWorkWith( createdCases[0], true ); 
                setErrorMessage('');
            } )
            .catch( err => {
                setBusy(false);
                setErrorMessage(err.message);
            } );
        } else {
            setBusy(false);
            setHighlightValidation(true);
            let newErrorMessage = '';
            if(!dataIsValid){
                newErrorMessage = 'Please check that all required fields are filled in. ';
            }
            if(!datesValid) {
                newErrorMessage += 'The dates and times you have entered are not all valid.';
            }else if(!datesInOrder){
                const finalTimeField = isHoldingRooms? 'Clear Time': 'Patient Contact Time';
                newErrorMessage += ` Dates and times are not in order. 
                Please check that Mobile Time is after Call Time, On Scene Time is after 
                Mobile Time and ${finalTimeField} is after On Scene Time.`;
            }
            setErrorMessage(newErrorMessage);
        }
    };

    return <div>
        <h1>{title}</h1>
        <ValidatedForm fieldDefinitions={fieldDefinitions}
                    formFieldData={formFieldData}
                    setFormFieldData={setFormFieldData}
                    setDataIsValid={setDataIsValid} 
                    validating={highlightValidation} 
                    setFormTouched={x=>null}
                    createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent}
                     />

        <ButtonHolder action={onSubmit}  actionTxt={'Submit'} enabled={!busy} cancel={cancelEdit} cancelTxt='Cancel' />
        <LoadingIndicator loading={busy} />
        <p>{errorMessage}</p>
    </div>

};

// we are overriding the standard time text box with a custom one
const createOptionalOverrideFieldComponent = fd => {
    if ('timetextbox' === fd.controltype) {
        return props => <div>
            {React.createElement(TimeTextBox, props)}
        </div>
    } else {
        return null;
    }
};

/** do some custom checks because the library doen't handle dates properly */
const datesAndTimesValid = (formFieldData) => {
    const isISODate = (dateString) => {
        // parse with regex
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return (regex.test(dateString));
    }

    const isISODateTime = (dateTimeString) => {
        // parse with regex
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
        return (regex.test(dateTimeString));
    }

    const parseISODateTime = (dateString) => {
        return Date.parse(dateString);
    }

    const callDateIsValid = isISODate(formFieldData.CallDate);
    const callTimeIsValid = isISODateTime(formFieldData.CallTime);
    const mobileTimeIsValid = isISODateTime(formFieldData.MobileTime);
    const onSceneTimeIsValid = isISODateTime(formFieldData.OnSceneTime);
    // WARNING: One of the fields differes between holding rooms and serco
    const finalTimeIsValid = isISODateTime(isHoldingRooms? formFieldData.ClearTime: 
        formFieldData.PatientContactTime);

    const datesValid = callDateIsValid && callTimeIsValid && mobileTimeIsValid && onSceneTimeIsValid && finalTimeIsValid;

    const callTime = parseISODateTime(formFieldData.CallTime);
    const mobileTime = parseISODateTime(formFieldData.MobileTime);
    const onSceneTime = parseISODateTime(formFieldData.OnSceneTime);

    // WARNING: One of the fields differes between holding rooms and serco
    const finalTime = parseISODateTime(isHoldingRooms? formFieldData.ClearTime: 
        formFieldData.PatientContactTime);

    const datesInOrder = callTime < mobileTime && mobileTime < onSceneTime && onSceneTime < finalTime;

    return {datesValid, datesInOrder};
};

export default CreateCasePage;