
const filterDataForForm = (fieldFormData, fieldDefinitions) => {

    if (null === fieldFormData  || (typeof fieldFormData !== 'object')) {
        throw new Error('fieldFormData must be an object');
    }
    if (null === fieldDefinitions || (!Array.isArray( fieldDefinitions ))) {
        throw new Error('fieldDefinitions must be an array');
    }

    const fieldNames = fieldDefinitions.map(field=>field.name);
    const result = {};
    Object.entries(fieldFormData)
        .filter(entry => fieldNames.includes( entry[0] ))
        .forEach(entry => result[entry[0]] = entry[1]);
    if(fieldDefinitions.find(field=>field.name==='obs_Time')) {
        result.obs_Time = result.obs_Time?? new Date().toISOString();
    }

    return result;
};

export default filterDataForForm;