import DeployedFromLocation                 from '../../CreateCasePage/DeployedFromLocation';
import EthincityOptionDefinitions           from '../../CreateCasePage/EthincityOptionDefinitions';
import NationalityOptionUpdateDefinitions   from './NationalityOptionUpdateDefinitions';


const SercoCaseDetailsFormDefinitions = [
    
    {
        "name": "Nationality",
        "text": "Nationality:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": NationalityOptionUpdateDefinitions
    },
    {
        "name": "Ethicity",
        "text": "Ethicity:",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": EthincityOptionDefinitions,
        "otherOption": "Other Ethicity"
    },
    {
        "name": "Deployed from",
        "text": "Deployed from",
        "enabled": "true",
        "required": "False",
        "controlType": "dropdownlist",
        "Options": DeployedFromLocation,
        "otherOption": "Other"
    },
        {
          "name": "CallDate",
          "text": "Call Date:",
          "enabled": "true",
          "required": "True",
          "controlType": "datetextbox",
          "pivotYAhead": 100,
          "maxDaysPrevious": 365,
          "maxDaysAhead": 0,
          "defaultValue": "today",
        },
        {
          "name": "CallTime",
          "text": "Call Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallDate"
        },
        {
          "name": "MobileTime",
          "text": "Mobile Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallTime",
          "referralInputValidate": "answers.CallTime < this",
          "referralInputError": "Mobile Time must be after Call Time"
        },
        {
          "name": "OnSceneTime",
          "text": "On Scene Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo":     "MobileTime",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time"
        },
        {
          "name": "PatientContactTime",
          "text": "Patient Contact Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "OnSceneTime",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time"
        },
        {
          "name": "ConsentToInformationSharing",
          "text": "Consent To Information Sharing",
          "required": "True",
          "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "true"
            },
            {
              "Text": "No",
              "Value": "false"
            }
          ]
        },
        {
          "name": "OtherComments",
          "text": "Additional Comments:",
          "enabled": "true",
          "required": "False",
          "mandatory": "false",
          "controlType": "TextBox",
          "width": "300",
          "rows": "5"
        }
];

export default SercoCaseDetailsFormDefinitions;
