
// clear values that are in the names in field definitions. Used to clear the grid
const getClearFormGridData = (fieldFormData, fieldDefinitions) => {

    if (null === fieldFormData  || (typeof fieldFormData !== 'object')) {
        throw new Error('fieldFormData must be an object');
    }
    if (null === fieldDefinitions || (!Array.isArray( fieldDefinitions ))) {
        throw new Error('fieldDefinitions must be an array');
    }

    const newFieldFormData = { ...fieldFormData };
    const fieldKeys = fieldDefinitions.map(f => f.name);
    fieldKeys.forEach(k => newFieldFormData[k] = '');

    // Always start with a blank form here so that the user can enter a new observation
    newFieldFormData.obs_Time = new Date().toISOString();

    return newFieldFormData;
};

export default getClearFormGridData;