
const MedicationSource = [
            { "Text": "PGD" },
            { "Text": "GSL" },
            { "Text": "Own medication" },
            { "Text": "Police custody medication" },
            { "Text": "Serco stock" },
            { "Text": "Prescription" }       
        ];

export default MedicationSource;