

const CaseSummary = ({caseInfo}) => {

    return <dl>
            <dt>Case ID</dt>
            <dd>{caseInfo.CaseReferrence}</dd>

            <dt>Claimant name</dt>
            <dd>{caseInfo.FirstName + ' ' + caseInfo.LastName}</dd>

            <dt>DoB</dt>
            <dd>{caseInfo.BirthDate}</dd>

            <dt>Location</dt>
            <dd>{caseInfo.FacilityName?? 'Not Assigned'}</dd>
            </dl>  
}

export default CaseSummary;