import React, {useEffect, useState}        from 'react';
import {OKCancel,ButtonHolder}  from 'iprs-react-library';
import CaseSummary from '../CaseUpdatePage/CaseSummary';

const SelectCasePage = ({ api, setcaseToWorkWith, setPageName }) => {

    if(null === api)                    throw new Error('API must not be null');
    if(null === setcaseToWorkWith)      throw new Error('setcaseToWorkWith must not be null');

    const [searchCaseID, setSearchCaseID]       = useState('');
    //const [caseInfo, setCaseInfo]               = useState(null);

    const [casesAssignedToClinician, setCasesAssignedToClinician] 
                                                = useState([]);

    const [err,setErr]  = useState('');

    useEffect(()=>{
        api.transact('ClinicianAssignedCases', { PartyID: 174234 })
        .then(response => response.apiResult)
        .then(apiResult => {
            setCasesAssignedToClinician(apiResult);
        })
        .catch(error => setErr(JSON.stringify(error)))
    },[]);

    const handleCancel = () => {
        setPageName('createCase');
    }

    const onSubmit = (caseInfo) => {
        setcaseToWorkWith( caseInfo );
    }

    return <>

        <div style={{width: '100%', clear: 'both', float: 'left'}} >
            {casesAssignedToClinician.map(caseOb => <CaseButton caseInfo={caseOb} onSubmit={onSubmit} />)}
            {/*<CaseButton caseInfo={cI1} onSubmit={onSubmit} />*/}
            {/*<CaseButton caseInfo={cI2} onSubmit={onSubmit} />*/}
        </div>
        <div style={{width: '100%', clear: 'both', float: 'left'}} >
            <ButtonHolder  action={handleCancel} actionTxt='Create a new Case' enabled={true} />
        </div>
        <p>{err}</p>
    </>
}

const CaseButton = ({ caseInfo, onSubmit }) => {

    if (!caseInfo.CaseReferrence) {
        throw new Error('Case must have a valid case referrence');
    }

    return <div>
        
        <CaseSummary caseInfo={caseInfo} /> 
        <ButtonHolder action={e=>onSubmit(caseInfo)} actionTxt={'Add further information to this case'} enabled={true} />
    </div>
}

export default SelectCasePage;


