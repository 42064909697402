
import React from "react";

const GridRow = ({rowData}) => {

    const { fieldDefinition, rowDataArray } = rowData;
    if (!Array.isArray(rowDataArray)) {
        throw new Error('GridRow needs a rowDataArray');
    }

    const rowTitle  = fieldDefinition.text;

    return <tr>
        <th style={{minWidth: '10em', textAlign: 'left', fontWeight: 'bold'}} >{rowTitle}</th>
        {rowDataArray.map( (rd,i) => <td key={i} style={{maxWidth: '12em', minWidth: '10em', textAlign: 'left'}} >
            {rd}
            </td> )}
        </tr>
}

export default GridRow;