
const NationalityOptionDefinitions = [
            {
                "Text": "Afghan",
                "Value": "1"
            },
            {
                "Text": "Albanian",
                "Value": "2"
            },
            {
                "Text": "Algerian",
                "Value": "3"
            },
            {
                "Text": "American",
                "Value": "4"
            },
            {
                "Text": "Andorran",
                "Value": "5"
            },
            {
                "Text": "Angolan",
                "Value": "6"
            },
            {
                "Text": "Anguillan",
                "Value": "7"
            },
            {
                "Text": "Argentine",
                "Value": "9"
            },
            {
                "Text": "Armenian",
                "Value": "10"
            },
            {
                "Text": "Australian",
                "Value": "11"
            },
            {
                "Text": "Austrian",
                "Value": "12"
            },
            {
                "Text": "Azerbaijani",
                "Value": "13"
            },
            {
                "Text": "Bahamian",
                "Value": "14"
            },
            {
                "Text": "Bahraini",
                "Value": "15"
            },
            {
                "Text": "Bangladeshi",
                "Value": "16"
            },
            {
                "Text": "Barbadian",
                "Value": "17"
            },
            {
                "Text": "Belarusian",
                "Value": "18"
            },
            {
                "Text": "Belgian",
                "Value": "19"
            },
            {
                "Text": "Belizean",
                "Value": "20"
            },
            {
                "Text": "Beninese",
                "Value": "21"
            },
            {
                "Text": "Bermudian",
                "Value": "22"
            },
            {
                "Text": "Bhutanese",
                "Value": "23"
            },
            {
                "Text": "Bolivian",
                "Value": "24"
            },
            {
                "Text": "Botswanan",
                "Value": "26"
            },
            {
                "Text": "Brazilian",
                "Value": "27"
            },
            {
                "Text": "British",
                "Value": "28"
            },
            {
                "Text": "British Virgin Islander",
                "Value": "29"
            },
            {
                "Text": "Bruneian",
                "Value": "30"
            },
            {
                "Text": "Bulgarian",
                "Value": "31"
            },
            {
                "Text": "Burkinan",
                "Value": "32"
            },
            {
                "Text": "Burmese",
                "Value": "33"
            },
            {
                "Text": "Burundian",
                "Value": "34"
            },
            {
                "Text": "Cambodian",
                "Value": "35"
            },
            {
                "Text": "Cameroonian",
                "Value": "36"
            },
            {
                "Text": "Canadian",
                "Value": "37"
            },
            {
                "Text": "Cape Verdean",
                "Value": "38"
            },
            {
                "Text": "Cayman Islander",
                "Value": "39"
            },
            {
                "Text": "Central African",
                "Value": "40"
            },
            {
                "Text": "Chadian",
                "Value": "41"
            },
            {
                "Text": "Chilean",
                "Value": "42"
            },
            {
                "Text": "Chinese",
                "Value": "43"
            },
            {
                "Text": "Citizen of Antigua and Barbuda",
                "Value": "8"
            },
            {
                "Text": "Citizen of Bosnia and Herzegovina",
                "Value": "25"
            },
            {
                "Text": "Citizen of Guinea-Bissau",
                "Value": "86"
            },
            {
                "Text": "Citizen of Kiribati",
                "Value": "112"
            },
            {
                "Text": "Citizen of Seychelles",
                "Value": "182"
            },
            {
                "Text": "Citizen of the Dominican Republic",
                "Value": "59"
            },
            {
                "Text": "Citizen of Vanuatu",
                "Value": "221"
            },
            {
                "Text": "Colombian",
                "Value": "44"
            },
            {
                "Text": "Comoran",
                "Value": "45"
            },
            {
                "Text": "Congolese (Congo)",
                "Value": "46"
            },
            {
                "Text": "Congolese (DRC)",
                "Value": "47"
            },
            {
                "Text": "Cook Islander",
                "Value": "48"
            },
            {
                "Text": "Costa Rican",
                "Value": "49"
            },
            {
                "Text": "Croatian",
                "Value": "50"
            },
            {
                "Text": "Cuban",
                "Value": "51"
            },
            {
                "Text": "Cymraes",
                "Value": "52"
            },
            {
                "Text": "Cymro",
                "Value": "53"
            },
            {
                "Text": "Cypriot",
                "Value": "54"
            },
            {
                "Text": "Czech",
                "Value": "55"
            },
            {
                "Text": "Danish",
                "Value": "56"
            },
            {
                "Text": "Djiboutian",
                "Value": "57"
            },
            {
                "Text": "Dominican",
                "Value": "58"
            },
            {
                "Text": "Dutch",
                "Value": "60"
            },
            {
                "Text": "East Timorese",
                "Value": "61"
            },
            {
                "Text": "Ecuadorean",
                "Value": "62"
            },
            {
                "Text": "Egyptian",
                "Value": "63"
            },
            {
                "Text": "Emirati",
                "Value": "64"
            },
            {
                "Text": "English",
                "Value": "65"
            },
            {
                "Text": "Equatorial Guinean",
                "Value": "66"
            },
            {
                "Text": "Eritrean",
                "Value": "67"
            },
            {
                "Text": "Estonian",
                "Value": "68"
            },
            {
                "Text": "Ethiopian",
                "Value": "69"
            },
            {
                "Text": "Faroese",
                "Value": "70"
            },
            {
                "Text": "Fijian",
                "Value": "71"
            },
            {
                "Text": "Filipino",
                "Value": "72"
            },
            {
                "Text": "Finnish",
                "Value": "73"
            },
            {
                "Text": "French",
                "Value": "74"
            },
            {
                "Text": "Gabonese",
                "Value": "75"
            },
            {
                "Text": "Gambian",
                "Value": "76"
            },
            {
                "Text": "Georgian",
                "Value": "77"
            },
            {
                "Text": "German",
                "Value": "78"
            },
            {
                "Text": "Ghanaian",
                "Value": "79"
            },
            {
                "Text": "Gibraltarian",
                "Value": "80"
            },
            {
                "Text": "Greek",
                "Value": "81"
            },
            {
                "Text": "Greenlandic",
                "Value": "82"
            },
            {
                "Text": "Grenadian",
                "Value": "83"
            },
            {
                "Text": "Guamanian",
                "Value": "84"
            },
            {
                "Text": "Guatemalan",
                "Value": "85"
            },
            {
                "Text": "Guinean",
                "Value": "87"
            },
            {
                "Text": "Guyanese",
                "Value": "88"
            },
            {
                "Text": "Haitian",
                "Value": "89"
            },
            {
                "Text": "Honduran",
                "Value": "90"
            },
            {
                "Text": "Hong Konger",
                "Value": "91"
            },
            {
                "Text": "Hungarian",
                "Value": "92"
            },
            {
                "Text": "Icelandic",
                "Value": "93"
            },
            {
                "Text": "Icelandic",
                "Value": "97"
            },
            {
                "Text": "Indian",
                "Value": "98"
            },
            {
                "Text": "Indian",
                "Value": "94"
            },
            {
                "Text": "Indonesian",
                "Value": "95"
            },
            {
                "Text": "Indonesian",
                "Value": "99"
            },
            {
                "Text": "Iranian",
                "Value": "100"
            },
            {
                "Text": "Iranian",
                "Value": "96"
            },
            {
                "Text": "Iraqi",
                "Value": "101"
            },
            {
                "Text": "Irish",
                "Value": "102"
            },
            {
                "Text": "Israeli",
                "Value": "103"
            },
            {
                "Text": "Italian",
                "Value": "104"
            },
            {
                "Text": "Ivorian",
                "Value": "105"
            },
            {
                "Text": "Jamaican",
                "Value": "106"
            },
            {
                "Text": "Japanese",
                "Value": "107"
            },
            {
                "Text": "Jordanian",
                "Value": "108"
            },
            {
                "Text": "Kazakh",
                "Value": "109"
            },
            {
                "Text": "Kenyan",
                "Value": "110"
            },
            {
                "Text": "Kittitian",
                "Value": "111"
            },
            {
                "Text": "Kosovan",
                "Value": "113"
            },
            {
                "Text": "Kuwaiti",
                "Value": "114"
            },
            {
                "Text": "Kyrgyz",
                "Value": "115"
            },
            {
                "Text": "Lao",
                "Value": "116"
            },
            {
                "Text": "Latvian",
                "Value": "117"
            },
            {
                "Text": "Lebanese",
                "Value": "118"
            },
            {
                "Text": "Liberian",
                "Value": "119"
            },
            {
                "Text": "Libyan",
                "Value": "120"
            },
            {
                "Text": "Liechtenstein citizen",
                "Value": "121"
            },
            {
                "Text": "Lithuanian",
                "Value": "122"
            },
            {
                "Text": "Luxembourger",
                "Value": "123"
            },
            {
                "Text": "Macanese",
                "Value": "124"
            },
            {
                "Text": "Macedonian",
                "Value": "125"
            },
            {
                "Text": "Malagasy",
                "Value": "126"
            },
            {
                "Text": "Malawian",
                "Value": "127"
            },
            {
                "Text": "Malaysian",
                "Value": "128"
            },
            {
                "Text": "Maldivian",
                "Value": "129"
            },
            {
                "Text": "Malian",
                "Value": "130"
            },
            {
                "Text": "Maltese",
                "Value": "131"
            },
            {
                "Text": "Marshallese",
                "Value": "132"
            },
            {
                "Text": "Martiniquais",
                "Value": "133"
            },
            {
                "Text": "Mauritanian",
                "Value": "134"
            },
            {
                "Text": "Mauritian",
                "Value": "135"
            },
            {
                "Text": "Mexican",
                "Value": "136"
            },
            {
                "Text": "Micronesian",
                "Value": "137"
            },
            {
                "Text": "Moldovan",
                "Value": "138"
            },
            {
                "Text": "Monegasque",
                "Value": "139"
            },
            {
                "Text": "Mongolian",
                "Value": "140"
            },
            {
                "Text": "Montenegrin",
                "Value": "141"
            },
            {
                "Text": "Montserratian",
                "Value": "142"
            },
            {
                "Text": "Moroccan",
                "Value": "143"
            },
            {
                "Text": "Mosotho",
                "Value": "144"
            },
            {
                "Text": "Mozambican",
                "Value": "145"
            },
            {
                "Text": "Namibian",
                "Value": "146"
            },
            {
                "Text": "Nauruan",
                "Value": "147"
            },
            {
                "Text": "Nepalese",
                "Value": "148"
            },
            {
                "Text": "New Zealander",
                "Value": "149"
            },
            {
                "Text": "Nicaraguan",
                "Value": "150"
            },
            {
                "Text": "Nigerian",
                "Value": "151"
            },
            {
                "Text": "Nigerien",
                "Value": "152"
            },
            {
                "Text": "Niuean",
                "Value": "153"
            },
            {
                "Text": "North Korean",
                "Value": "154"
            },
            {
                "Text": "Northern Irish",
                "Value": "155"
            },
            {
                "Text": "Norwegian",
                "Value": "156"
            },
            {
                "Text": "Omani",
                "Value": "157"
            },
            {
                "Text": "Pakistani",
                "Value": "158"
            },
            {
                "Text": "Palauan",
                "Value": "159"
            },
            {
                "Text": "Palestinian",
                "Value": "160"
            },
            {
                "Text": "Panamanian",
                "Value": "161"
            },
            {
                "Text": "Papua New Guinean",
                "Value": "162"
            },
            {
                "Text": "Paraguayan",
                "Value": "163"
            },
            {
                "Text": "Peruvian",
                "Value": "164"
            },
            {
                "Text": "Pitcairn Islander",
                "Value": "165"
            },
            {
                "Text": "Polish",
                "Value": "166"
            },
            {
                "Text": "Portuguese",
                "Value": "167"
            },
            {
                "Text": "Prydeinig",
                "Value": "168"
            },
            {
                "Text": "Puerto Rican",
                "Value": "169"
            },
            {
                "Text": "Qatari",
                "Value": "170"
            },
            {
                "Text": "Romanian",
                "Value": "171"
            },
            {
                "Text": "Russian",
                "Value": "172"
            },
            {
                "Text": "Rwandan",
                "Value": "173"
            },
            {
                "Text": "Salvadorean",
                "Value": "174"
            },
            {
                "Text": "Sammarinese",
                "Value": "175"
            },
            {
                "Text": "Samoan",
                "Value": "176"
            },
            {
                "Text": "Sao Tomean",
                "Value": "177"
            },
            {
                "Text": "Saudi Arabian",
                "Value": "178"
            },
            {
                "Text": "Scottish",
                "Value": "179"
            },
            {
                "Text": "Senegalese",
                "Value": "180"
            },
            {
                "Text": "Serbian",
                "Value": "181"
            },
            {
                "Text": "Sierra Leonean",
                "Value": "183"
            },
            {
                "Text": "Singaporean",
                "Value": "184"
            },
            {
                "Text": "Slovak",
                "Value": "185"
            },
            {
                "Text": "Slovenian",
                "Value": "186"
            },
            {
                "Text": "Solomon Islander",
                "Value": "187"
            },
            {
                "Text": "Somali",
                "Value": "188"
            },
            {
                "Text": "South African",
                "Value": "189"
            },
            {
                "Text": "South Korean",
                "Value": "190"
            },
            {
                "Text": "South Sudanese",
                "Value": "191"
            },
            {
                "Text": "Spanish",
                "Value": "192"
            },
            {
                "Text": "Sri Lankan",
                "Value": "193"
            },
            {
                "Text": "St Helenian",
                "Value": "194"
            },
            {
                "Text": "St Lucian",
                "Value": "195"
            },
            {
                "Text": "Stateless",
                "Value": "196"
            },
            {
                "Text": "Sudanese",
                "Value": "197"
            },
            {
                "Text": "Surinamese",
                "Value": "198"
            },
            {
                "Text": "Swazi",
                "Value": "199"
            },
            {
                "Text": "Swedish",
                "Value": "200"
            },
            {
                "Text": "Swiss",
                "Value": "201"
            },
            {
                "Text": "Syrian",
                "Value": "202"
            },
            {
                "Text": "Taiwanese",
                "Value": "203"
            },
            {
                "Text": "Tajik",
                "Value": "204"
            },
            {
                "Text": "Tanzanian",
                "Value": "205"
            },
            {
                "Text": "Thai",
                "Value": "206"
            },
            {
                "Text": "Togolese",
                "Value": "207"
            },
            {
                "Text": "Tongan",
                "Value": "208"
            },
            {
                "Text": "Trinidadian",
                "Value": "209"
            },
            {
                "Text": "Tristanian",
                "Value": "210"
            },
            {
                "Text": "Tunisian",
                "Value": "211"
            },
            {
                "Text": "Turkish",
                "Value": "212"
            },
            {
                "Text": "Turkmen",
                "Value": "213"
            },
            {
                "Text": "Turks and Caicos Islander",
                "Value": "214"
            },
            {
                "Text": "Tuvaluan",
                "Value": "215"
            },
            {
                "Text": "Ugandan",
                "Value": "216"
            },
            {
                "Text": "Ukrainian",
                "Value": "217"
            },
            {
                "Text": "Uruguayan",
                "Value": "218"
            },
            {
                "Text": "Uzbek",
                "Value": "219"
            },
            {
                "Text": "Vatican citizen",
                "Value": "220"
            },
            {
                "Text": "Venezuelan",
                "Value": "222"
            },
            {
                "Text": "Vietnamese",
                "Value": "223"
            },
            {
                "Text": "Vincentian",
                "Value": "224"
            },
            {
                "Text": "Wallisian",
                "Value": "225"
            },
            {
                "Text": "Welsh",
                "Value": "226"
            },
            {
                "Text": "Yemeni",
                "Value": "227"
            },
            {
                "Text": "Zambian",
                "Value": "228"
            },
            {
                "Text": "Zimbabwean",
                "Value": "229"
            }
        ];

export default NationalityOptionDefinitions;