import medicationOptionDefinitions  from '../../CreateCasePage/MediacationOptionDefinitions';
import medicationRoute              from '../../CreateCasePage/MediationRoute';
import MedicationSource             from './MedicationSource';

const SercoExaminationManagementConclusionFormDefinitions = [
    {
        "name": "Airway",
        "text": "Airway",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Breathing",
        "text": "Breathing",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Circulation",
        "text": "Circulation",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Disability",
        "text": "Disability",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Exposure",
        "text": "Exposure",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Mental State",
        "text": "Mental State",
        "required": false,
        "controlType": "Heading"
    },
    {
        "name": "Demeanour",
        "text": "Demeanour",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Speech",
        "text": "Speech",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Thoughts",
        "text": "Thoughts",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Hallucinations_Delusions",
        "text": "Hallucinations / Delusions",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Mood",
        "text": "Mood",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Orientation",
        "text": "Orientation",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Risky behaviour including self-harm",
        "text": "Risky behaviour (including self-harm)",
        "required": false,
        "controlType": "TextBox",
        "rows": 5
    },
    {
        "name": "Additional Information",
        "text": "Additional Information",
        "required": false,
        "controlType": "TextBox",
        "rows": 5
    },
    {
        "name": "Assessment Outcome",
        "text": "Assessment Outcome",
        "required": false,
        "controlType": "TextBox",
        "rows": 5
    },
    {
        "name": "Pathway",
        "text": "Pathway",
        "enabled": "true",
        "required": true,
        "controlType": "dropdownlist",
        "width": 100,
        Options: [
            {
                "Text": "ED",
                "Value": "ed"
            },
            {
                "Text": "Police Custody",
                "Value": "police_custody"
            },
            {
                "Text": "Prison",
                "Value": "prison"
            } ,
            {
                "Text": "L&D",
                "Value": "l_and_d"
            } ,
            {
                "Text": "Alcohol",
                "Value": "alcohol"
            } ,
            {
                "Text": "Drugs",
                "Value": "drugs"
            } ,
            {
                "Text": "Housing",
                "Value": "housing"
            },
            {
                "Text": "Employment",
                "Value": "employment"
            } ,
            {
                "Text": "Safeguarding",
                "Value": "safeguarding"
            } ,
            {
                "Text": "Veteran",
                "Value": "veteran"
            },
            {
                "Text": "Youth Offending Service",
                "Value": "youth_offending_service"
            },
            {
                "Text": "Woman's Refuge",
                "Value": "womans_refuge"
            },
            {
                "Text": "GP",
                "Value": "gp"
            },
            {
                "Text": "Border Force",
                "Value": "border_force"
            },
            {
                "Text": "Mitie",
                "Value": "mitie"
            },
            {
                "Text": "Serco",
                "Value": "serco"
            },
            {
                "Text": "N/A",
                "Value": "N_A"
            }
        ],
        "uniqueID": 2
    },
    {
        "name": "plan",
        "text": "Plan",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300",
        "uniqueID": 3
    },
    {
        "name": "MedicationAdministeredUpdate",
        "text": "Medication Administered:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "Medication1",
        "text": "Medication 1,",
        "controlType": "Heading",
        "if": "'true' === values.MedicationAdministeredUpdate",
    },
    {
        "name": "MedicationName1Update",
        "text": "Type of Medication Administered 1",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationTime1Update",
        "text": "Time Administered 1",
        "controlType": "timetextbox",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationNumberOff1Update",
        "text": "Number Administered",
        "controlType": "TextBox",
        "regex": "\\s*\\d\\d?\\s*",
        "validationWarning": "Number 1-99",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationRoute1Update",
        "text": "Route of Medication Administered",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationRoute, 
    },
    
    {
        "name": "MedicationSource1Update",
        "text": "Source of Medication",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": MedicationSource, 
    },
    
    {
        "name": "Medication2",
        "text": "Medication 2",
        "controlType": "Heading",
        "if": "'true' === values.MedicationAdministeredUpdate",
    },
    {
        "name": "MedicationName2Update",
        "text": "Type of Medication Administered 2",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationTime2Update",
        "text": "Time Administered 2",
        "controlType": "timetextbox",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationNumberOff2Update",
        "text": "Number Administered",
        "controlType": "TextBox",
        "regex": "\\s*\\d\\d?\\s*",
        "validationWarning": "Number 1-99",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationRoute2Update",
        "text": "Route of Medication Administered",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationRoute, 
    },
    {
        "name": "MedicationSource2Update",
        "text": "Source of Medication",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": MedicationSource, 
    },


    
    {
        "name": "Medication3",
        "text": "Medication 3",
        "controlType": "Heading",
        "if": "'true' === values.MedicationAdministeredUpdate",
    },
    {
        "name": "MedicationName3Update",
        "text": "Type of Medication Administered 3",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationTime3Update",
        "text": "Time Administered 2",
        "controlType": "timetextbox",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationNumberOff3Update",
        "text": "Number Administered",
        "controlType": "TextBox",
        "regex": "\\s*\\d\\d?\\s*",
        "validationWarning": "Number 1-99",
        "if": "'true' === values.MedicationAdministeredUpdate"
    },
    {
        "name": "MedicationRoute3Update",
        "text": "Route of Medication Administered",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": medicationRoute, 
    },
    {
        "name": "MedicationSource3Update",
        "text": "Source of Medication",
        "controlType": "DropDownList",
        "if": "'true' === values.MedicationAdministeredUpdate",
        "Options": MedicationSource, 
    },



    {
        "name": "Other treatment - additional information",
        "text": "Other treatment/additional information",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300",
        "uniqueID": 5
    },
    {
        "name": "Patient Sent To Hospital",
        "text": "Patient Sent To Hospital",
        "controlType": "dropdownlist",
        "width": 100,
        Options: [
            {
                "Text": "Yes"
            },
            {
                "Text": "No"
            }
        ]
    },
    
    {
        "name": "Medical Assessment Completed",
        "text": "Medical Assessment Completed",
        "controlType": "CheckBox",
    },
    
        {
          "name": "ClearTimeUpdate",
          "text": "Time Medical Examination Concluded",
          "required": true,
          "controlType": "timetextbox",
          "RelativeTo":     "PatientContactTime",
          "referralInputValidate": "answers.OnSceneTime < this",
          "referralInputError": "Clear Time must be after On Scene Time"
        },
    {
        "name": "TimeCaseConcluded",
        "text": "Time Case Concluded",
        "required": true,
        "controlType": "timetextbox"
    },
];

export default SercoExaminationManagementConclusionFormDefinitions;