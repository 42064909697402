
import React                from 'react'
import ReactDOM             from 'react-dom/client';

import './index.css';
import AeromedReferralApp   from './components/AeromedReferralApp/AeromedReferralApp';
import API                  from 'iprs-api-library';
import reportWebVitals      from './reportWebVitals';
import GetAeromedClient     from './components/AeromedReferralApp/GetAeromedClient';

const client            = GetAeromedClient();

console.log('window.location.pathname', window.location.pathname);

const apiURL = process.env.REACT_APP_IPRS_WEB_API_URL;
const api = new API(null, apiURL);
//api.setAlternateNetworkConnectionFunction(mochNetwork);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <AeromedReferralApp api={ api}  client={ client } />
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();