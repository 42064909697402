import { useCallback, useState } from 'react';
import { ButtonHolder, CustomerCaseFeedbackStars, TwoSwitch } from '../../../node_modules/iprs-react-library/dist/index';
import CaseSummary from './CaseSummary';
import CaseUpdateMainPage from './CaseUpdateMainPage';

import holdingRoomsPatientDetailsForm   from './CasePageFormDefinitions/HoldingRoomsPatientDetailsForm';
import holdingRoomsFirstPart            from './CasePageFormDefinitions/HoldingRoomsFirstPart';
import holdingRoomsSecondPart           from './CasePageFormDefinitions/HoldingRoomsSecondPart';

import SercoCaseDetailsFormDefinitions  from './CasePageFormDefinitions/SercoCaseDetailsFormDefinitions';
import SercoHistoryFormDefinitions      from './CasePageFormDefinitions/SercoHistoryFormDefinitions';
import ObservationFieldDefinitions      from './ObservationFormGrid/ObservationFieldDefinitions';

import SercoExaminationManagementConclusionFormDefinitions                  from './CasePageFormDefinitions/SercoExaminationManagementConclusionFormDefinitions';
import GetAeromedClient                 from '../AeromedReferralApp/GetAeromedClient';

import filterDataForForm                from './filterDataForForm';
import CaseReview from './CaseReview';

const GRID_PAGE                         = 'clinicalObservations';
const patientDetailsForm                = 'patientDetailsForm';
const caseUpdateFirstPart               = 'caseUpdateFirstPart';
const caseUpdateSecondPart              = 'caseUpdateSecondPart';

const client            = GetAeromedClient();
const isHoldingRooms    = 'holding-rooms' === client;

const mappingOfPageNameToFormDefinition = {};
mappingOfPageNameToFormDefinition[patientDetailsForm]   = isHoldingRooms? holdingRoomsPatientDetailsForm:   SercoCaseDetailsFormDefinitions;
mappingOfPageNameToFormDefinition[caseUpdateFirstPart]  = isHoldingRooms? holdingRoomsFirstPart:            SercoHistoryFormDefinitions;
mappingOfPageNameToFormDefinition[caseUpdateSecondPart] = isHoldingRooms? holdingRoomsSecondPart:           SercoExaminationManagementConclusionFormDefinitions;
mappingOfPageNameToFormDefinition[GRID_PAGE]            = ObservationFieldDefinitions;

const formDataToGridData = fieldFormData => {

    const fieldDefinitions = mappingOfPageNameToFormDefinition[GRID_PAGE];

    const result = filterDataForForm(fieldFormData, fieldDefinitions);

    return result;
}

const CaseUpdateForm = props => {

    const { api, caseInfo, cancelEdit, caseIsCreatedHere } = props;
    const caseID = caseInfo.CaseID;
    const caseReferrence = caseInfo.CaseReferrence;

    const [detachCaseConfirm, setDetachCaseConfirm] = useState(false);

    const [errorMessage, setErrorMessage]           = useState('');

    const [pageName, setPageName]                   = 
        useState(caseIsCreatedHere? caseUpdateFirstPart: patientDetailsForm);

    const detachTheCaseOrConfirm = useCallback(()=>{
        if (!detachCaseConfirm) {
            setDetachCaseConfirm(true);
        } else {
            api.transact('ClinicianAssignedCaseRemove', {
                'CaseReferrence': caseReferrence
            })
            .then(r=>cancelEdit())
            .catch(e =>  setErrorMessage('Problem Detaching case ' + e.Message));
        }
    }, [api,setDetachCaseConfirm,detachCaseConfirm,setErrorMessage,caseReferrence]);

    const fieldDefinitions                          = mappingOfPageNameToFormDefinition[pageName];

    return <>

         <div>
            <h1>Updating Case</h1>
            <CaseSummary caseInfo={caseInfo} /> 
        </div>

        <TwoSwitch test={!detachCaseConfirm} >
            <CaseUpdateMainPage api={api} caseInfo={caseInfo} cancelEdit={cancelEdit} caseIsCreatedHere={caseIsCreatedHere}
                patientDetailsForm={patientDetailsForm}  caseUpdateFirstPart={caseUpdateFirstPart}
                fieldDefinitions={fieldDefinitions} formDataToGridData={formDataToGridData} pageName={pageName} 
                setPageName={setPageName} />
        </TwoSwitch>

        <div style={{ width: '100%', clear: 'both', float: 'left' }}>
            <ButtonHolder action={detachTheCaseOrConfirm} actionTxt={detachCaseConfirm ? 'Confirm Remove' : 'Remove case from your list'}
                cancel={e => setDetachCaseConfirm(false)} cancelTxt={detachCaseConfirm ? 'Cancel - Do not remove case from your list' : null}
                enabled={true} />
            <TwoSwitch test={detachCaseConfirm} >
                <>
                    <p style={{ marginBottom: '5em' }} >Please review the data below before confirming that 
                    you which to take this case out of your list. You cannot undo this.</p>
                    <CaseReview caseInfo={caseInfo} api={api} />
                    <CustomerCaseFeedbackStars api={api} caseID={caseID} />
                </>
            </TwoSwitch>

            <p>{errorMessage}</p>
        </div>
    </>
};

export {GRID_PAGE,patientDetailsForm,caseUpdateFirstPart,caseUpdateSecondPart,mappingOfPageNameToFormDefinition};
export default CaseUpdateForm;