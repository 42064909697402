
const injuryRegions = [
    {
        "Text": "abdominal pain"
    },
    {
        "Text": "an acute confused state"
    },
    {
        "Text": "Anxiety / Depression"
    },
    {
        "Text": "bites and stings"
    },
    {
        "Text": "breathing difficulties"
    },
    {
        "Text": "chest pain"
    },
    {
        "Text": "coughs and colds"
    },
    {
        "Text": "cuts and grazes"
    },
    {
        "Text": "dental / tooth - pain / problems"
    },
    {
        "Text": "Diabetes"
    },
    {
        "Text": "ear and throat infections"
    },
    {
        "Text": "eye problems"
    },
    {
        "Text": "feverish illness in adults"
    },
    {
        "Text": "feverish illness in children"
    },
    {
        "Text": "fits that aren't stopping"
    },
    {
        "Text": "intoxication"
    },
    {
        "Text": "loss of consciousness"
    },
    {
        "Text": "medication advice"
    },
    {
        "Text": "minor head injuries"
    },
    {
        "Text": "minor scalds and burns"
    },
    {
        "Text": "severe allergic reactions"
    },
    {
        "Text": "severe bleeding that can't be stopped"
    },
    {
        "Text": "severe burns or scalds"
    },
    {
        "Text": "skin infections and rashes"
    },
    {
        "Text": "sprains and strains"
    },
    {
        "Text": "stroke"
    },
    {
        "Text": "suspected broken limbs"
    },
    {
        "Text": "vomiting and diarrhoea"
    },
    {
        "Text": "withdrawal"
    },
    {
        "Text": "Family Screening"
    },
    {
        "Text": "Medication Assessment"
    },
];

export default injuryRegions; 