
const GlasgowComaScaleOptions = [
    {
        "Text": "GCS 3",
        "Value": "3"
    },
    {
        "Text": "GCS 4",
        "Value": "4"
    },
    {
        "Text": "GCS 5",
        "Value": "5"
    },
    {
        "Text": "GCS 6",
        "Value": "6"
    },
    {
        "Text": "GCS 7",
        "Value": "7"
    },
    {
        "Text": "GCS 8",
        "Value": "8"
    },
    {
        "Text": "GCS 9",
        "Value": "9"
    },
    {
        "Text": "GCS 10",
        "Value": "10"
    },
    {
        "Text": "GCS 11",
        "Value": "11"
    },
    {
        "Text": "GCS 12",
        "Value": "12"
    },
    {
        "Text": "GCS 13",
        "Value": "13"
    },
    {
        "Text": "GCS 14",
        "Value": "14"
    },
    {
        "Text": "GCS 15",
        "Value": "15"
    }
]

export default GlasgowComaScaleOptions