import injuryRegions        from "./InjuryRegions";

const SercoHistoryFormDefinitions = [

    {
        "name":                     "TimeStarted",
        "text":                     "Time Started",
        "controlType":              "timetextbox",
        "RelativeTo":               "OnSceneTime",
        "referralInputValidate":    "answers.OnSceneTime < this",
        "referralInputError":       "On Time Started must be after On Scene Time"
    },
    {
        "name": "VerbalConsent",
        "text": "Verbal Consent",
        "required": true,
        "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "yes"
            },
            {
              "Text": "No",
              "Value": "no"
            }
          ]
    },
    {
        "name": "patientUnderstandInformation",
        "text": "Does the patient understand the information",
        "if": "Answers.VerbalConsent==='no'",
        "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "yes"
            },
            {
              "Text": "No",
              "Value": "no"
            }
          ]
    },
    {
        "name": "patientAbleToRetainInformation",
        "text": "Is the patient able to retain the information",
        "if": "Answers.VerbalConsent==='no'",
        "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "yes"
            },
            {
              "Text": "No",
              "Value": "no"
            }
          ]
    },
    {
        "name": "patientProcessAbleInformationAndArriveAtDecision",
        "text": "Is the patient able to process the information and arrive at a decision",
        "if": "Answers.VerbalConsent==='no'",
        "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "yes"
            },
            {
              "Text": "No",
              "Value": "no"
            }
          ]
    },
    {
        "name": "PatientAbleCommunicateDecision",
        "text": "Is the patient able to communicate the decision",
        "if": "Answers.VerbalConsent==='no'",
        "controlType": "dropdownlist",
          "Options": [
            {
              "Text": "Yes",
              "Value": "yes"
            },
            {
              "Text": "No",
              "Value": "no"
            }
          ]
    },
    {
        "name": "AreYouTreatingBestInterest",
        "text": "Are you treating in their best interest",
        "controlType": "checkbox",
        "if": "Answers.VerbalConsent==='no' && (Answers.patientUnderstandInformation=='no'||Answers.patientAbleToRetainInformation=='no'||Answers.patientProcessAbleInformationAndArriveAtDecision=='no'||Answers.PatientAbleCommunicateDecision=='no')",
    },
    {
        "name": "AreYouTreatingBestInterestNotes",
        "text": "Notes",
        "controlType": "textbox",
        "rows": 5,
        "if": "Answers.VerbalConsent==='no' && (Answers.patientUnderstandInformation=='no'||Answers.patientAbleToRetainInformation=='no'||Answers.patientProcessAbleInformationAndArriveAtDecision=='no'||Answers.PatientAbleCommunicateDecision=='no')",
    },
    
    {
        "name": "ConsentToCareAndShare",
        "text": "Consent To Care And Share",
        "required": "True",
        "controlType": "dropdownlist",
        "Options": [
            {
                "Text": "Yes",
                "Value": "yes"
            },
            {
                "Text": "No",
                "Value": "no"
            }
        ]
    },

    {
        "name": "PrimaryComplaint",
        "text": "Primary Complaint",
        "enabled": "true",
        "required": true,
        "controlType": "dropdownlist",
        "width": 300,
        Options: injuryRegions,
        "otherOption": "Other",
        "uniqueID": 2
    },
    {
        "name": "SecondaryComplaint",
        "text": "Secondary Complaint",
        "enabled": "true",
        "required": false,
        "controlType": "dropdownlist",
        "width": 300,
        Options: injuryRegions
    },
    {
        "name": "HistoryOfPresentingComplaint",
        "text": "History of the presenting complaint:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "pmhx",
        "text": "PMHx",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "PrescribedMedication",
        "text": "Prescribed Medication",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "OTCMedication",
        "text": "OTC Medication",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "HasAllergies",
        "text": "Allergies",
        "required": true,
        "controlType": "dropdownlist",
         Options: [
            {
                "Text":  "Yes",
                "Value": 'True'
            },
            {
                "Text":  "No",
                "Value": 'False'
            }
         ]
    },
    {
        "name": "AllergyDetails",
        "text": "Allergies - Specify",
        "controlType": "TextBox",
        "required": false,
        "if": "Answers.HasAllergies==='True'"
    },
    {
        "name": "Alcohol History",
        "text": "Alcohol History",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Illicit Drug History",
        "text": "Illicit Drug History",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Past Psychiatric History",
        "text": "Past Psychiatric History",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Self-Harm History",
        "text": "Self-Harm History",
        "required": false,
        "controlType": "TextBox"
    },
    {
        "name": "Social History ",
        "text": "Social History ",
        "required": false,
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Smoker",
        "text": "Smoker",
        "required": false,
        "controlType": "dropdownlist",
        "width": 300,
        Options: [
            {
                "Text":  "Never Smoked",
                "Value": "Never Smoked"
            },
            {
                "Text":  "Current Smoker",
                "Value": "Current Smoker"
            },
            {
                "Text":  "Previously Smoked",
                "Value": "Previously Smoked"
            }
        ]
    },
    {
        "name": "DisabilityYesNo",
        "text": "Disability",
        "required": true,
        "controlType": "dropdownlist",
        "width": 200,
        Options: [
            {
                "Text":  "Yes",
                "Value": "yes"
            },
            {
                "Text":  "No",
                "Value": "no"
            }
        ]
    },
    {
        "name": "Disability-Specific",
        "text": "Disability - specify",
        "controlType": "TextBox",
        "if": "Answers.DisabilityYesNo=='yes'"
    },
    {
        "name": "Pregnancy",
        "text": "Pregnancy ",
        "required": true,
        "controlType": "dropdownlist",
         Options: [
            {
                "Text":  "Yes",
                "Value": 'True'
            },
            {
                "Text":  "No",
                "Value": 'False'
            }
         ]
    },
    {
        "name": "Pregnancy Comment",
        "text": "Pregnancy Comment ",
        "controlType": "TextBox",
        "if": "Answers.Pregnancy==='True'"
    },
    {
        "name": "Dependants Safeguarding concerns",
        "text": "Dependants / Safeguarding concerns ",
        "controlType": "textbox",
        "rows": 5
    },
    
];

export default SercoHistoryFormDefinitions;
