
const DeployedFromLocation = [
            {
                "Text": "Bristol"
            },
            {
                "Text": "Croydon"
            },
            {
                "Text": "Eastleigh"
            },
            {
                "Text": "Exeter"
            },
            {
                "Text": "Gatwick"
            },
            {
                "Text": "Greenford"
            },
            {
                "Text": "New Southgate"
            },
            {
                "Text": "Stansted"
            }
        ]

export default DeployedFromLocation;