
const medicationOptionDefinitions = [
            {
                "Text": "Adrenaline 1 :1,000 - 1mg/1ml",
                "Value": "1"
            },
            {
                "Text": "Aspirin 300mg dispersible",
                "Value": "2"
            },
            {
                "Text": "CALPOL 2+ MONTHS",
                "Value": "3"
            },
            {
                "Text": "CALPOL SIX PLUS 6+ YEARS",
                "Value": "4"
            },
            {
                "Text": "Cyclizine 50mg",
                "Value": "5"
            },
            {
                "Text": "Diazemuls 10mg/2ml",
                "Value": "6"
            },
            {
                "Text": "Diazepam 5mg",
                "Value": "7"
            },
            {
                "Text": "Dihydrocodiene 30mg",
                "Value": "8"
            },
            {
                "Text": "Dioralyte",
                "Value": "9"
            },
            {
                "Text": "GLUCOSE 10% 500ml",
                "Value": "10"
            },
            {
                "Text": "Glucose gel",
                "Value": "11"
            },
            {
                "Text": "GTN",
                "Value": "12"
            },
            {
                "Text": "Imodium - Loperamide 2mg",
                "Value": "13"
            },
            {
                "Text": "Ipratropium 250 mcg - 250mcg/1ml",
                "Value": "14"
            },
            {
                "Text": "Nurofen - Ibuprofen 400mg",
                "Value": "15"
            },
            {
                "Text": "Omeprazole 20mg",
                "Value": "16"
            },
            {
                "Text": "Paracetamol 500mg",
                "Value": "17"
            },
            {
                "Text": "Piriton - Chlorphenamine 4mg",
                "Value": "18"
            },
            {
                "Text": "Rectal Diazepam 5mg",
                "Value": "19"
            },
            {
                "Text": "Salbutamol 5mg - 5mg/2.5ml",
                "Value": "20"
            },
            {
                "Text": "SODIUM CHLORIDE 0.9% 500mls",
                "Value": "21"
            },
            {
                "Text": "None",
                "Value": "22"
            },
            {
                "Text": "Lyclear cream",
                "Value": "23"
            },
            {
                "Text": "Paracetamol 1g",
                "Value": "24"
            }
        ];

export default medicationOptionDefinitions;