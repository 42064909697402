import GlasgowComaScaleOptions from './GlasgowComaScaleOptions';

const ObservationFieldDefinitions = [

    {
        "name": "obs_Time",
        "text": "Time",
        "controlType": "timetextbox",
        "width": "300"
    },
    {
        "text": "AVPU",
        "name": "obs_AVPU",
        "controlType": "dropdownlist",
        "required": false,
        "Options": [
            {
                "Text": "Alert",
                "Value": "alert"
            },
            {
                "Text": "Verbal",
                "Value": "verbal"
            },
            {
                "Text": "Pain",
                "Value": "pain"
            },
            {
                "Text": "Unresponsive",
                "Value": "unresponsive"
            }
        ]
    },
    {
        "name": "obs_RespRate",
        "text": "Resp/rate",
        "controlType": "TextBox",
        "--regex": "^\\s*(\d{1-3})\\s*$",
        "validationWarning": "Percentage (1-100)",
        "width": "300"
    },
    {
        "name": "obs_SPO2",
        "text": "SPO2 %",
        "controlType": "TextBox",
        "--regex": "^\\s*(\d{1-3})\\s*$",
        "validationWarning": "Percentage (1-100)",
    },
    {
        "name": "obs_PulseRate",
        "text": "Pulse rate",
        "enabled": "true",
        "--required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "obs_BP",
        "text": "Blood Pressure",
        "enabled": "true",
        "--required": "True",
        "controlType": "TextBox"
    },
    {
        "name": "obs_Colour",
        "text": "Colour",
        "enabled": "true",
        "--required": "True",
        "controlType": "dropdownlist",
        "Options": [
            {
                "Text": "Normal",
                "Value": "normal"
            },
            {
                "Text": "Pale",
                "Value": "pale"
            },
            {
                "Text": "Flushed",
                "Value": "flushed"
            },
            {
                "Text": "Cyanosed",
                "Value": "cyanosed"
            }
        ]
    },
    {
        "name": "obs_temperature",
        "text": "Temperature",
        "enabled": "true",
        "--required": "True",
        "controlType": "TextBox",
        "--regex": "^\\s*\\d+(\\.\\d+)\\s*$",
        "validationWarning": "Number required",
    },
    {
        "name": "obs_BM",
        "text": "BM",
        "enabled": "true",
        "--required": "True",
        "controlType": "TextBox"
    },
    {
        "name": "obs_PainScore",
        "text": "Pain Score",
        "enabled": "true",
        "--required": "True",
        "controlType": "TextBox",
        "--regex": "^\\s*(0|(10)|(0?[0-9]))\\s*$",
        "validationWarning": "0-10 followed by optional text",
    },
    {
        "name": "obs_GCS",
        "text": "GCS",
        "controlType": "dropdownlist",
        "Options": GlasgowComaScaleOptions
    },
    {
        "name": "obs_NEWS2",
        "text": "NEWS2",
        "controlType": "TextBox"
    },
    {
        "name": "obs_COWS_Score",
        "text": "COWS Score",
        "controlType": "TextBox"
    },
    {
        "name": "obs_CIWA_Score",
        "text": "CIWA Score",
        "controlType": "TextBox"
    }
];

export default ObservationFieldDefinitions;