import { getURLPathArray }          from 'iprs-react-library';

const GetAeromedClient = () => {

    const urlPath = getURLPathArray();
  
    let result = null;
    if (2 == urlPath.length){
        const firstPath = ('' + urlPath[1]).trim().toLowerCase();
        if ('holding-rooms' == firstPath) {
            result =  'holding-rooms';
        }else if ('serco' == firstPath) {
            result = 'serco';
        } 
    } 

    return result;
};

export default GetAeromedClient;