import injuryRegions        from "./InjuryRegions";

const holdingRoomsFirstPart = [
    {
        "name": "PrimaryComplaintUpdate",
        "text": "Primary Injury / Illness",
        "enabled": "true",
        "required": false,
        "controlType": "dropdownlist",
        "width": 100,
        Options: injuryRegions,
        "otherOption": "Other",
        "uniqueID": 2
    },
    {
        "name": "SecondaryComplaintUpdate",
        "text": "Secondary Injury / Illness",
        "enabled": "true",
        "required": false,
        "controlType": "dropdownlist",
        "width": 100,
        Options: injuryRegions,
        "otherOption": "Other",
        "uniqueID": 2
    },
    {
        "name": "HistoryOfPresentingComplaint",
        "text": "History of the presenting complaint:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "pmhx",
        "text": "PMHx",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "PrescribedMedication",
        "text": "Prescribed Medication",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "OTCMedication",
        "text": "OTC Medication",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Allergies",
        "text": "Allergies",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    }
];

export default holdingRoomsFirstPart;
