const medicationRoute = [
    {
                "Text": "Oral Route",
                "Value": "Oral"
    },
    {
                "Text": "Intravenous Route",
                "Value": "Intravenous"
    },
    {
                "Text": "Intramuscular",
                "Value": "Intramuscular Route"
    },
    {
                "Text": "Subcutaneous Route",
                "Value": "Subcutaneous"
    },
    {
                "Text": "Rectal Route",
                "Value": "Rectal"
    },
    {
                "Text": "Inhaled Route",
                "Value": "Inhaled"
    },
    {
                "Text": "Nasal Route",
                "Value": "Nasal"
    }
];

export default medicationRoute;