import { useState }                 from 'react';
import SelectCasePage               from '../SelectCasePage/SelectCasePage';
import CreateCasePage               from '../CreateCasePage/CreateCasePage';
import CaseUpdateForm               from '../CaseUpdatePage/CaseUpdateForm';

const AeromedReferralAppPages = ({ api, ClientID }) => {
    
    const [pageName, setPageName] = useState('selectCase');

    const [caseToWorkWith, setCaseToWorkWith]       = useState(null);

    const [caseIsCreatedHere, setCaseIsCreatedHere] = useState(false);

    const changeCaseToWorkWith = (caseInfo, caseIsNew = false) => {
        setCaseToWorkWith(caseInfo);
        setPageName('updateCase');
        setCaseIsCreatedHere(caseIsNew); // new cases are created here
    }

    const cancelEdit = () => {
        setPageName('selectCase');
    }

    let body = null;
    switch (pageName){ 

        case 'createCase':
            body = <CreateCasePage api={api} 
                setcaseToWorkWith={changeCaseToWorkWith} 
                cancelEdit={cancelEdit} />
            break;
        case 'updateCase':
            body = <CaseUpdateForm api={api} 
                 caseInfo={caseToWorkWith} 
                 cancelEdit={cancelEdit}
                 caseIsCreatedHere={caseIsCreatedHere} />;
            break;
        default:
        case 'selectCase':
            body = <SelectCasePage api={api} 
                setcaseToWorkWith={changeCaseToWorkWith} 
                setPageName={setPageName} />
            break;
    } 

    return <>{body}</>
};

export default AeromedReferralAppPages;