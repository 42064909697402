import { LoadingIndicator, TwoSwitch } from "iprs-react-library";

import {mappingOfPageNameToFormDefinition} from './CaseUpdateForm';
import React, { useEffect, useState } from "react";
import { dataArrayToObject, getGridDataFromSubmittedPages } from "./CaseUpdateMainPage";
import GridDisplay from "./ObservationFormGrid/GridDisplay";
import medicationOptionDefinitions from '../CreateCasePage/MediacationOptionDefinitions';

const GRID_PAGE                         = 'clinicalObservations';
const patientDetailsForm                = 'patientDetailsForm';
const caseUpdateFirstPart               = 'caseUpdateFirstPart';
const caseUpdateSecondPart              = 'caseUpdateSecondPart';

const pages = [GRID_PAGE, patientDetailsForm, caseUpdateFirstPart, caseUpdateSecondPart];

const CaseReview = ({caseInfo, api}) => {

    const caseReferrence = caseInfo.CaseReferrence;

    const [fieldFormData, setFieldFormData]         = useState(null);
    const [errorMessage, setErrorMessage]           = useState(null);

    const [gridPreviousData, setGridPreviousData]   = useState([]);

    useEffect(()=>{
        
        api.transact('CaseAdditionalVersionedDataListSubmittedKeys', { CaseReferrence: caseReferrence, SendDetail: true})
            .then(r => r.apiResult)
            .then(r => {
                const fieldDataObject = dataArrayToObject(r.VersionedDataForThisCase);
                const fieldDataObjectMedsResolved = resolveMedicationNames(fieldDataObject);
                setFieldFormData(fieldDataObjectMedsResolved);
                setGridPreviousData(getGridDataFromSubmittedPages(r.KeysSavedForThisCase, r.VersionedDataForThisCase));
            })
            .catch(e=>{
                setErrorMessage(e.message);
            });
    }, [caseReferrence, api]);

    return <div>

        <LoadingIndicator loading={null===errorMessage && null===fieldFormData} />

        <TwoSwitch test={null!==errorMessage}>
            <p>{errorMessage}</p>
        </TwoSwitch >

        <TwoSwitch test={null!==fieldFormData}>
            <>
            <h2>Case Review</h2>

            {pages.map(pageName => {
                const formDefinition = mappingOfPageNameToFormDefinition[pageName];
                return <form key={pageName}>
                    <h3>{pageName}</h3>
                    <TwoSwitch test={pageName===GRID_PAGE}>
                        <GridDisplay 
                            fieldDefinitions={formDefinition} 
                            formFieldData={gridPreviousData}
                        />
                    <Summary
                        fieldFormData={fieldFormData}
                        formDefinition={formDefinition}
                        />
                    </TwoSwitch>
                </form>
            })}
            </>
        </TwoSwitch>
    </div>
}

const Summary = ({fieldFormData, formDefinition}) => {

    return <dl>
        {formDefinition.map(field => {
            const {name, text} = field;
            const value = fieldFormData[name];
            return <React.Fragment key={name}>
                <dt id={name} >{text}</dt>
                <dd aria-describedby={name}
                 data-testid={name} >{value}</dd>
            </React.Fragment>
        })}
    </dl>
} 

const resolveMedicationNames = (fieldDataObject) => {

    const medications = medicationOptionDefinitions.reduce((acc, med) => {
        acc[med.Value] = med.Text;
        return acc;
    }, {});

    const medicationRegex = /MedicationName(\d+)Update/;
    const medicationFields = Object.keys(fieldDataObject).filter(k => medicationRegex.test(k));
    medicationFields.forEach(medField => {
        const medicationID = fieldDataObject[medField];
        fieldDataObject[medField] = medications[medicationID] || medicationID;
    });
    return fieldDataObject;
}   

export default CaseReview;