import medicationOptionDefinitions from './MediacationOptionDefinitions';
import NationalityOptionDefinitions from './NationalityOptionDefinitions';

const fieldDefinitions = [
    {
        "name": "ClientId",
        "value": 155409, 
        "controlType": "Hidden"
    },
    {
        "name": "ServiceActivityId",
        "value": 2008, 
        "controlType": "Hidden"
    },
    {
        "name": "PatientHeading",
        "text": "Patient Details",
        "enabled": "true",
        "required": "False",
        "controlType": "Heading"
    },
    {
        "name": "HealthcareProfessionName",
        "text": "Healthcare Profession Name:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Forename",
        "text": "Patient First Name:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Surname",
        "text": "Patient Last Name:",
        "enabled": "false",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "DOB",
        "text": "Date of Birth:",
        "enabled": "true",
        "required": "True",
        "controlType": "datetextbox",
        "pivotYAhead": 0,
        "maxDaysAhead": 0,
        "defaultValue": "",
    },
    {
        "name": "Gender",
        "text": "Gender:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Male",
                "Value": "M"
            },
            {
                "Text": "Female",
                "Value": "F"
            },
            {
                "Text": "Intersex",
                "Value": "I"
            },
            {
                "Text": "Non Binary",
                "Value": "N"
            },
            {
                "Text": "Prefer not to say",
                "Value": "U"
            }
        ]
    },
    {
        "name": "Nationality",
        "text": "Nationality:",
        "enabled": "true",
        "required": "True",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "Nationality_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkNationalityID",
        "Options": NationalityOptionDefinitions
    },
    {
        "name": "CustodyNumber",
        "text": "Custody Number:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300"
    },
    {
        "name": "Site",
        "text": "Location:",
        "enabled": "true",
        "required": "True",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "Facility_List",
        "DataSourceText": "FacilityName",
        "DataSourceValue": "pkFacilityID",
        "Options": [
            {
                "Text": "Dover",
                "Value": "61711"
            },
            {
                "Text": "France",
                "Value": "61690"
            },
            {
                "Text": "Gatwick",
                "Value": "61695"
            },
            {
                "Text": "Heathrow",
                "Value": "61698"
            },
            {
                "Text": "Luton",
                "Value": "61705"
            },
            {
                "Text": "Manston",
                "Value": "61784"
            },
            {
                "Text": "Stansted",
                "Value": "61708"
            }
        ]
    },
    {
        "name": "Division",
        "text": "Sub Location:",
        "enabled": "true",
        "required": false,
        "mandatory": "false",
        "controlType": "DropDownList"
    },
    {
          "name": "CallDate",
          "text": "Call Date:",
          "enabled": "true",
          "required": "True",
          "controlType": "datetextbox",
          "pivotYAhead": 100,
          "maxDaysPrevious": 365,
          "maxDaysAhead": 0,
          "defaultValue": "today",
        },
        {
          "name": "CallTime",
          "text": "Call Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallDate"
        },
        {
          "name": "MobileTime",
          "text": "Mobile Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo": "CallTime",
          "referralInputValidate": "answers.CallTime < this",
          "referralInputError": "Mobile Time must be after Call Time"
        },
        {
          "name": "OnSceneTime",
          "text": "On Scene Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo":     "CallTime",
          "referralInputValidate": "answers.MobileTime < this",
          "referralInputError": "On Scene Time must be after Mobile Time"
        },
        {
          "name": "ClearTime",
          "text": "Clear Time:",
          "enabled": "true",
          "required": "True",
          "controlType": "timetextbox",
          "RelativeTo":     "CallTime",
          "referralInputValidate": "answers.OnSceneTime < this",
          "referralInputError": "Clear Time must be after On Scene Time"
        },
    {
        "name": "MedicalAssessmentCompleted",
        "text": "Medical Assessment Completed:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "CRFNumber",
        "text": "CRF Number:",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300",
        "if": "$scope.Answers.MedicalAssessmentCompleted == 'true'"
    },
    {
        "name": "CovidTest",
        "text": "Covid-19 Test:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "referralInputValidate": "'false' != answers.MedicalAssessmentCompleted || 'false' != this",
        "referralInputError": "If you say no to 'Medical Assessment Completed' you need to say yes here",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "LFTResult",
        "text": "LFT Result:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "if": "$scope.Answers.CovidTest == 'true'",
        "Options": [
            {
                "Text": "Negative",
                "Value": "N"
            },
            {
                "Text": "Positive",
                "Value": "P"
            },
            {
                "Text": "Declined",
                "Value": "D"
            }
        ]
    },
    {
        "name": "MentalCapacity",
        "text": "Mental Capacity:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "ConsentToShareAndCare",
        "text": "Consent to Care and Share:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Given",
                "Value": "G"
            },
            {
                "Text": "Not Given",
                "Value": "N"
            },
            {
                "Text": "Presumed",
                "Value": "P"
            },
            {
                "Text": "Best Interest",
                "Value": "B"
            }
        ]
    },
    {
        "text": "Primary Injury/ Illness",
        "name": "PrimaryComplaint",
        "controlType": "DropDownList",
        "DataSourceName": "ChiefComplaint_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkChiefComplaintID",
        "ValueType": "Numeric",
        "required": "True",
        "if": "!(answers.CovidTest=='true' && answers.MedicalAssessmentCompleted=='false')",
        "Exclude": "1054",
        "Options": [
            {
                "Text": "Abdominal Distension/Swelling",
                "Value": "1009"
            },
            {
                "Text": "Abdominal Pain",
                "Value": "15"
            },
            {
                "Text": "Abnormal Blood Sugar",
                "Value": "1010"
            },
            {
                "Text": "Abnormal Sweating",
                "Value": "1011"
            },
            {
                "Text": "Abnormal Test Or Measurement",
                "Value": "1012"
            },
            {
                "Text": "Abrasions/Contusions",
                "Value": "1013"
            },
            {
                "Text": "Allergic reaction",
                "Value": "1007"
            },
            {
                "Text": "Altered Mental Status",
                "Value": "1014"
            },
            {
                "Text": "An Acute Confused State",
                "Value": "1001"
            },
            {
                "Text": "Animal Bite/Attack",
                "Value": "1015"
            },
            {
                "Text": "Assault",
                "Value": "1016"
            },
            {
                "Text": "Back Pain",
                "Value": "1017"
            },
            {
                "Text": "Behaviour Change",
                "Value": "1018"
            },
            {
                "Text": "Bloody Stool",
                "Value": "1019"
            },
            {
                "Text": "Bony Deformity",
                "Value": "1020"
            },
            {
                "Text": "Burn",
                "Value": "1021"
            },
            {
                "Text": "Cast Change/Problem",
                "Value": "1022"
            },
            {
                "Text": "Change In Skin Colour",
                "Value": "1023"
            },
            {
                "Text": "Chest Pain",
                "Value": "1003"
            },
            {
                "Text": "Chills/Rigours",
                "Value": "1024"
            },
            {
                "Text": "Constipation",
                "Value": "1025"
            },
            {
                "Text": "Cough",
                "Value": "12"
            },
            {
                "Text": "Cut or Wound",
                "Value": "6"
            },
            {
                "Text": "Dead On Arrival",
                "Value": "1026"
            },
            {
                "Text": "Decreased Oral Intake",
                "Value": "1027"
            },
            {
                "Text": "Deliberate Self-Harm",
                "Value": "1028"
            },
            {
                "Text": "Dental/Oral - Pain/Swelling/Mass",
                "Value": "1029"
            },
            {
                "Text": "Diabetes",
                "Value": "1083"
            },
            {
                "Text": "Diarrhoea - Non-Bloody",
                "Value": "1030"
            },
            {
                "Text": "Difficulty Speaking",
                "Value": "1031"
            },
            {
                "Text": "Dizziness",
                "Value": "1032"
            },
            {
                "Text": "Drowning",
                "Value": "1033"
            },
            {
                "Text": "Ear - Redness/Pain/Discharge",
                "Value": "1034"
            },
            {
                "Text": "Ear And Throat Infections",
                "Value": "9"
            },
            {
                "Text": "Electrical/Lightening Injury",
                "Value": "1035"
            },
            {
                "Text": "Epistaxis/Nosebleed",
                "Value": "1036"
            },
            {
                "Text": "Eye - Redness/Pain/Discharge",
                "Value": "11"
            },
            {
                "Text": "Fall",
                "Value": "1037"
            },
            {
                "Text": "Female Genital- Pain/Swelling/Discharge",
                "Value": "1038"
            },
            {
                "Text": "Fever",
                "Value": "1039"
            },
            {
                "Text": "Feverish Illness In Adults",
                "Value": "13"
            },
            {
                "Text": "Feverish Illness In Children",
                "Value": "14"
            },
            {
                "Text": "Fits That Are Not Stopping",
                "Value": "1002"
            },
            {
                "Text": "Flu-Like Illness",
                "Value": "1040"
            },
            {
                "Text": "Foreign Body- Eye/Ear/Nose",
                "Value": "1041"
            },
            {
                "Text": "Foreign Body- Ingested",
                "Value": "1042"
            },
            {
                "Text": "Generalised Weakness/Malaise",
                "Value": "1043"
            },
            {
                "Text": "Head injury",
                "Value": "5"
            },
            {
                "Text": "Headache",
                "Value": "1044"
            },
            {
                "Text": "Hearing Loss/Tinnitus",
                "Value": "1045"
            },
            {
                "Text": "Hiccups",
                "Value": "1046"
            },
            {
                "Text": "Ingestion/Poisoning",
                "Value": "1047"
            },
            {
                "Text": "Insect bite/sting",
                "Value": "7"
            },
            {
                "Text": "Intoxication",
                "Value": "2"
            },
            {
                "Text": "Itching",
                "Value": "1048"
            },
            {
                "Text": "Loss Of Consciousness",
                "Value": "1000"
            },
            {
                "Text": "Male Genital- Pain/Swelling/Discharge",
                "Value": "1049"
            },
            {
                "Text": "Medication administration",
                "Value": "17"
            },
            {
                "Text": "Minor Scalds And Burns",
                "Value": "8"
            },
            {
                "Text": "Motor Deficit",
                "Value": "1050"
            },
            {
                "Text": "Myalgia/Arthralgia",
                "Value": "1051"
            },
            {
                "Text": "Nausea/Vomiting, Non-Bloody",
                "Value": "1052"
            },
            {
                "Text": "Neck Pain/Stiffness",
                "Value": "1053"
            },
            {
                "Text": "None",
                "Value": "1054"
            },
            {
                "Text": "Off Bailer Risk Assessment",
                "Value": "19"
            },
            {
                "Text": "Pain And/Or Swelling- Lips/Face",
                "Value": "1056"
            },
            {
                "Text": "Pain And/Or Swelling- Lower Extremity",
                "Value": "1057"
            },
            {
                "Text": "Pain And/Or Swelling- Skin",
                "Value": "1058"
            },
            {
                "Text": "Pain And/Or Swelling- Upper Extremity",
                "Value": "1059"
            },
            {
                "Text": "Pain- Skin/Soft Tissue",
                "Value": "1055"
            },
            {
                "Text": "Palpitations",
                "Value": "1060"
            },
            {
                "Text": "Pregnancy-Related Complaint",
                "Value": "1061"
            },
            {
                "Text": "Rash",
                "Value": "1062"
            },
            {
                "Text": "Rectal Pain/Mass/Swelling",
                "Value": "1063"
            },
            {
                "Text": "Referred For Diagnostic/Therapeutic Procedure",
                "Value": "1064"
            },
            {
                "Text": "Rhinorrhoea/Congestion",
                "Value": "1065"
            },
            {
                "Text": "Road Traffic Accident",
                "Value": "1066"
            },
            {
                "Text": "Seizure",
                "Value": "1067"
            },
            {
                "Text": "Sensory Deficit",
                "Value": "1068"
            },
            {
                "Text": "Severe Bleeding That Cannott Be Stopped",
                "Value": "1006"
            },
            {
                "Text": "Severe Burns Or Scalds",
                "Value": "1008"
            },
            {
                "Text": "Sexual Assault",
                "Value": "1069"
            },
            {
                "Text": "Shortness of breath",
                "Value": "1005"
            },
            {
                "Text": "Skin Infections And Rashes",
                "Value": "10"
            },
            {
                "Text": "Sprains And Strains",
                "Value": "3"
            },
            {
                "Text": "Stroke",
                "Value": "1004"
            },
            {
                "Text": "Suicide Attempt/Ideation",
                "Value": "1070"
            },
            {
                "Text": "Suspected Broken Limbs",
                "Value": "4"
            },
            {
                "Text": "Suture Removal",
                "Value": "1071"
            },
            {
                "Text": "Swelling/Oedema, Generalised",
                "Value": "1072"
            },
            {
                "Text": "Syncope",
                "Value": "1073"
            },
            {
                "Text": "Throat Pain",
                "Value": "1074"
            },
            {
                "Text": "Trauma/Injury",
                "Value": "1075"
            },
            {
                "Text": "Tube/Catheter Problem",
                "Value": "1076"
            },
            {
                "Text": "Ulcers/Sore",
                "Value": "1077"
            },
            {
                "Text": "Unresponsive",
                "Value": "1078"
            },
            {
                "Text": "Urinary- Pain/Blood/Frequency",
                "Value": "1079"
            },
            {
                "Text": "Vision Loss/Blurred Vision/Diplopia",
                "Value": "1080"
            },
            {
                "Text": "Vomiting And Diarrhoea",
                "Value": "16"
            },
            {
                "Text": "Vomiting Blood",
                "Value": "1081"
            },
            {
                "Text": "Withdrawal",
                "Value": "1"
            },
            {
                "Text": "Wound Care/Dressing Change",
                "Value": "1082"
            }
        ]
    },
    {
        "text": "Secondary Injury/ Illness",
        "name": "SecondaryComplaint",
        "controlType": "DropDownList",
        "DataSourceName": "ChiefComplaint_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkChiefComplaintID",
        "ValueType": "Numeric",
        "required": "True",
        "if": "!(answers.CovidTest=='true' && answers.MedicalAssessmentCompleted=='false')",
        "Options": [
            {
                "Text": "Abdominal Distension/Swelling",
                "Value": "1009"
            },
            {
                "Text": "Abdominal Pain",
                "Value": "15"
            },
            {
                "Text": "Abnormal Blood Sugar",
                "Value": "1010"
            },
            {
                "Text": "Abnormal Sweating",
                "Value": "1011"
            },
            {
                "Text": "Abnormal Test Or Measurement",
                "Value": "1012"
            },
            {
                "Text": "Abrasions/Contusions",
                "Value": "1013"
            },
            {
                "Text": "Allergic reaction",
                "Value": "1007"
            },
            {
                "Text": "Altered Mental Status",
                "Value": "1014"
            },
            {
                "Text": "An Acute Confused State",
                "Value": "1001"
            },
            {
                "Text": "Animal Bite/Attack",
                "Value": "1015"
            },
            {
                "Text": "Assault",
                "Value": "1016"
            },
            {
                "Text": "Back Pain",
                "Value": "1017"
            },
            {
                "Text": "Behaviour Change",
                "Value": "1018"
            },
            {
                "Text": "Bloody Stool",
                "Value": "1019"
            },
            {
                "Text": "Bony Deformity",
                "Value": "1020"
            },
            {
                "Text": "Burn",
                "Value": "1021"
            },
            {
                "Text": "Cast Change/Problem",
                "Value": "1022"
            },
            {
                "Text": "Change In Skin Colour",
                "Value": "1023"
            },
            {
                "Text": "Chest Pain",
                "Value": "1003"
            },
            {
                "Text": "Chills/Rigours",
                "Value": "1024"
            },
            {
                "Text": "Constipation",
                "Value": "1025"
            },
            {
                "Text": "Cough",
                "Value": "12"
            },
            {
                "Text": "Cut or Wound",
                "Value": "6"
            },
            {
                "Text": "Dead On Arrival",
                "Value": "1026"
            },
            {
                "Text": "Decreased Oral Intake",
                "Value": "1027"
            },
            {
                "Text": "Deliberate Self-Harm",
                "Value": "1028"
            },
            {
                "Text": "Dental/Oral - Pain/Swelling/Mass",
                "Value": "1029"
            },
            {
                "Text": "Diabetes",
                "Value": "1083"
            },
            {
                "Text": "Diarrhoea - Non-Bloody",
                "Value": "1030"
            },
            {
                "Text": "Difficulty Speaking",
                "Value": "1031"
            },
            {
                "Text": "Dizziness",
                "Value": "1032"
            },
            {
                "Text": "Drowning",
                "Value": "1033"
            },
            {
                "Text": "Ear - Redness/Pain/Discharge",
                "Value": "1034"
            },
            {
                "Text": "Ear And Throat Infections",
                "Value": "9"
            },
            {
                "Text": "Electrical/Lightening Injury",
                "Value": "1035"
            },
            {
                "Text": "Epistaxis/Nosebleed",
                "Value": "1036"
            },
            {
                "Text": "Eye - Redness/Pain/Discharge",
                "Value": "11"
            },
            {
                "Text": "Fall",
                "Value": "1037"
            },
            {
                "Text": "Female Genital- Pain/Swelling/Discharge",
                "Value": "1038"
            },
            {
                "Text": "Fever",
                "Value": "1039"
            },
            {
                "Text": "Feverish Illness In Adults",
                "Value": "13"
            },
            {
                "Text": "Feverish Illness In Children",
                "Value": "14"
            },
            {
                "Text": "Fits That Are Not Stopping",
                "Value": "1002"
            },
            {
                "Text": "Flu-Like Illness",
                "Value": "1040"
            },
            {
                "Text": "Foreign Body- Eye/Ear/Nose",
                "Value": "1041"
            },
            {
                "Text": "Foreign Body- Ingested",
                "Value": "1042"
            },
            {
                "Text": "Generalised Weakness/Malaise",
                "Value": "1043"
            },
            {
                "Text": "Head injury",
                "Value": "5"
            },
            {
                "Text": "Headache",
                "Value": "1044"
            },
            {
                "Text": "Hearing Loss/Tinnitus",
                "Value": "1045"
            },
            {
                "Text": "Hiccups",
                "Value": "1046"
            },
            {
                "Text": "Ingestion/Poisoning",
                "Value": "1047"
            },
            {
                "Text": "Insect bite/sting",
                "Value": "7"
            },
            {
                "Text": "Intoxication",
                "Value": "2"
            },
            {
                "Text": "Itching",
                "Value": "1048"
            },
            {
                "Text": "Loss Of Consciousness",
                "Value": "1000"
            },
            {
                "Text": "Male Genital- Pain/Swelling/Discharge",
                "Value": "1049"
            },
            {
                "Text": "Medication administration",
                "Value": "17"
            },
            {
                "Text": "Minor Scalds And Burns",
                "Value": "8"
            },
            {
                "Text": "Motor Deficit",
                "Value": "1050"
            },
            {
                "Text": "Myalgia/Arthralgia",
                "Value": "1051"
            },
            {
                "Text": "Nausea/Vomiting, Non-Bloody",
                "Value": "1052"
            },
            {
                "Text": "Neck Pain/Stiffness",
                "Value": "1053"
            },
            {
                "Text": "None",
                "Value": "1054"
            },
            {
                "Text": "Off Bailer Risk Assessment",
                "Value": "19"
            },
            {
                "Text": "Pain And/Or Swelling- Lips/Face",
                "Value": "1056"
            },
            {
                "Text": "Pain And/Or Swelling- Lower Extremity",
                "Value": "1057"
            },
            {
                "Text": "Pain And/Or Swelling- Skin",
                "Value": "1058"
            },
            {
                "Text": "Pain And/Or Swelling- Upper Extremity",
                "Value": "1059"
            },
            {
                "Text": "Pain- Skin/Soft Tissue",
                "Value": "1055"
            },
            {
                "Text": "Palpitations",
                "Value": "1060"
            },
            {
                "Text": "Pregnancy-Related Complaint",
                "Value": "1061"
            },
            {
                "Text": "Rash",
                "Value": "1062"
            },
            {
                "Text": "Rectal Pain/Mass/Swelling",
                "Value": "1063"
            },
            {
                "Text": "Referred For Diagnostic/Therapeutic Procedure",
                "Value": "1064"
            },
            {
                "Text": "Rhinorrhoea/Congestion",
                "Value": "1065"
            },
            {
                "Text": "Road Traffic Accident",
                "Value": "1066"
            },
            {
                "Text": "Seizure",
                "Value": "1067"
            },
            {
                "Text": "Sensory Deficit",
                "Value": "1068"
            },
            {
                "Text": "Severe Bleeding That Cannott Be Stopped",
                "Value": "1006"
            },
            {
                "Text": "Severe Burns Or Scalds",
                "Value": "1008"
            },
            {
                "Text": "Sexual Assault",
                "Value": "1069"
            },
            {
                "Text": "Shortness of breath",
                "Value": "1005"
            },
            {
                "Text": "Skin Infections And Rashes",
                "Value": "10"
            },
            {
                "Text": "Sprains And Strains",
                "Value": "3"
            },
            {
                "Text": "Stroke",
                "Value": "1004"
            },
            {
                "Text": "Suicide Attempt/Ideation",
                "Value": "1070"
            },
            {
                "Text": "Suspected Broken Limbs",
                "Value": "4"
            },
            {
                "Text": "Suture Removal",
                "Value": "1071"
            },
            {
                "Text": "Swelling/Oedema, Generalised",
                "Value": "1072"
            },
            {
                "Text": "Syncope",
                "Value": "1073"
            },
            {
                "Text": "Throat Pain",
                "Value": "1074"
            },
            {
                "Text": "Trauma/Injury",
                "Value": "1075"
            },
            {
                "Text": "Tube/Catheter Problem",
                "Value": "1076"
            },
            {
                "Text": "Ulcers/Sore",
                "Value": "1077"
            },
            {
                "Text": "Unresponsive",
                "Value": "1078"
            },
            {
                "Text": "Urinary- Pain/Blood/Frequency",
                "Value": "1079"
            },
            {
                "Text": "Vision Loss/Blurred Vision/Diplopia",
                "Value": "1080"
            },
            {
                "Text": "Vomiting And Diarrhoea",
                "Value": "16"
            },
            {
                "Text": "Vomiting Blood",
                "Value": "1081"
            },
            {
                "Text": "Withdrawal",
                "Value": "1"
            },
            {
                "Text": "Wound Care/Dressing Change",
                "Value": "1082"
            }
        ]
    },
    {
        "name": "MedicationAdministered",
        "text": "Medication Administered:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "MedicationName1",
        "text": "Type of Medication Administered 1:",
        "enabled": "true",
        "required": "True",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationName2",
        "text": "Type of Medication Administered 2:",
        "enabled": "true",
        "required": "False",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationName3",
        "text": "Type of Medication Administered 3:",
        "enabled": "true",
        "required": "False",
        "mandatory": "true",
        "controlType": "DropDownList",
        "DataSourceName": "MedicationName_List",
        "DataSourceText": "Description",
        "DataSourceValue": "pkMedicationNameID",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": medicationOptionDefinitions, 
    },
    {
        "name": "MedicationPatientsOwn",
        "text": "Medication Used Patients Own?:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true'",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "MedicationPatientsNotOwnReason",
        "text": "Reason why Patients own medication not used:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true' && $scope.Answers.MedicationPatientsOwn == 'false'",
        "Options": [
            {
                "Text": "Not Available",
                "Value": "Not Available"
            },
            {
                "Text": "Not Required",
                "Value": "Not Required"
            },
            {
                "Text": "Not Suitable",
                "Value": "Not Suitable"
            },
            {
                "Text": "Not Recorded/ Documented in patients notes",
                "Value": "Not Recorded/ Documented in patients notes"
            },
            {
                "Text": "Not Recognised",
                "Value": "Not Recognised"
            }
        ]
    },
    {
        "name": "MedicationFromStock",
        "text": "Medication used from Stock?:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "if": "$scope.Answers.MedicationAdministered == 'true' && $scope.Answers.MedicationPatientsOwn == 'false'",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "PatientSentToHospital",
        "text": "Patient Sent to Hospital?:",
        "enabled": "true",
        "required": "True",
        "mandatory": "false",
        "controlType": "DropDownList",
        "Options": [
            {
                "Text": "Yes",
                "Value": "true"
            },
            {
                "Text": "No",
                "Value": "false"
            }
        ]
    },
    {
        "name": "PatientSentToHospitalReason",
        "text": "Reason Patient Sent to Hospital:",
        "enabled": "true",
        "required": "True",
        "controlType": "DropDownList",
        "if": "$scope.Answers.PatientSentToHospital == 'true'",
        "Options": [
            {
                "Text": "Medical emergency 999 called",
                "Value": "Medical emergency 999 called"
            },
            {
                "Text": "Unable to treat/manage on scene",
                "Value": "Unable to treat/manage on scene"
            },
            {
                "Text": "Xray required",
                "Value": "Xray required"
            },
            {
                "Text": "Bloods required",
                "Value": "Bloods required"
            },
            {
                "Text": "Unable to obtain appropriate medication",
                "Value": "Unable to obtain appropriate medication"
            }
        ]
    },
    {
        "name": "OtherComments",
        "text": "Additional Comments:",
        "enabled": "true",
        "required": "False",
        "mandatory": "false",
        "controlType": "TextBox",
        "width": "300",
        "rows": 5
    }
];

export default fieldDefinitions;