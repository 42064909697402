import React, { useCallback, useEffect, useState }          from "react";
import { TwoSwitch, ButtonHolder, ValidatedForm, Tabs, formValidate, CustomerCaseFeedbackStars, LoadingIndicator }         
                                          from "iprs-react-library";
import GridDisplay                      from './ObservationFormGrid/GridDisplay';
import getClearFormGridData                 from './getClearFormData';
import TimeTextBox                      from "../TMPFieldOverrides/TimeTextBox";
import filterDataForForm                from './filterDataForForm';
import {GRID_PAGE,patientDetailsForm,caseUpdateFirstPart,caseUpdateSecondPart} from './CaseUpdateForm';

const dataSetNameForPageName = (pageName, gridNumber) => {

    let result;
    if (GRID_PAGE === pageName) {
        result = pageName + '_' + gridNumber;
    } else {
        result = pageName;
    }
    return result;
}

const getGridPageNumberFromSubmittedPages = (submittedKeys) => {

    let i = 0;
    for (; i < 2; i++) {
        if(!submittedKeys.includes(GRID_PAGE + '_' + i)) break;
    }

    return i;
}


/**
 * Converts an array of objects with Key and Value properties to a single object
 */
const dataArrayToObject = dataArray => 
    dataArray
    .sort((a, b) => b.DataSetName.localeCompare(a.DataSetName))
    .reduce((accumulator, currentValue) => {
        const {Key: key, Value: value} = currentValue;
        // we have sorted so that the latest data will contain the latest grid data
        // but we don't hold duplicates
        if(accumulator.hasOwnProperty(key)) {
            const gridRegex = /clinicalObservations/i;
            if(!currentValue.DataSetName.match(gridRegex)) {
                console.log('----------- Duplicate key ---------- ' + key + ' -- ', currentValue);
            }
        }else{
            accumulator[key] = value;
        }
        return accumulator;
    }, {});

const getGridDataFromSubmittedPages = (submittedKeys, submittedData) => {
    
        const result = [];
        for (let i = 0; i < 3; i++) {
            const key = GRID_PAGE + '_' + i;
            if(submittedKeys.includes(key)) {
                const dataArray = submittedData.filter(d => d.DataSetName === key);
                const dataObject = dataArrayToObject(dataArray);
                result.push(dataObject);
            }
        }

        return result;
}

const getSubmissionsRemaining = (pageName, gridPageNumber, pagesCompleted) => {

    let result;
    if (GRID_PAGE === pageName) {
        result = 3 - gridPageNumber;
    } else {
        result = pagesCompleted.includes(pageName)? 0: 1;
    }

    return result;
}

const getRemainingSumissionsMessage = (submissionsRemaining, pageName) => {
    
    let result;
    if (GRID_PAGE === pageName) {
        const plural = 1 === submissionsRemaining? '': 's';
        result = `You can submit this form ${submissionsRemaining} more time${plural}`;
    } else {
        result = `You can submit this form once`;
    }
    return result;
}

const CaseUpdateMainPage = ({ api, caseInfo, cancelEdit, caseIsCreatedHere,
    fieldDefinitions, formDataToGridData, pageName, setPageName}) => {

    const { CaseReferrence: caseReferrence, CaseID: caseID }    = caseInfo;
    if (typeof caseReferrence !== 'string' || 0 === caseReferrence.trim().length) {
        throw new Error('caseReferrence must be a valid string');
    }

    const [errorMessage, setErrorMessage]           = useState('');

    const [loading, setLoading]                     = useState(false);

    // 0-2
    const [gridPageNumber, setGridPageNumber]       = useState(0);

    const [gridPreviousData, setGridPreviousData]   = useState([]);

    const [fieldFormData, setFieldFormData]         = useState({});

    const [highlightValidation, setHighlightValidation]
                                                    = useState(false);
    
    const [pagesCompleted, setPagesCompleted]       = useState([]);

    // THIS SHOULD BE IN THE LIBRARY
    const dataIsValid = 
        fieldDefinitions?.reduce((accumulator, currentValue) => accumulator && formValidate(currentValue, fieldFormData, 0), true);

    const submissionsRemaining                      = getSubmissionsRemaining( pageName, gridPageNumber, pagesCompleted );

    const [resubmitEnabled, setResubmitEnabled]     = useState(false);

    useEffect(()=>{
        setLoading(true);
        api.transact('CaseAdditionalVersionedDataListSubmittedKeys', { CaseReferrence: caseReferrence, SendDetail: true})
            .then(r => r.apiResult)
            .then(r => {
                setLoading(false);
                setPagesCompleted(r.KeysSavedForThisCase);
                const gridPageNumber = getGridPageNumberFromSubmittedPages(r.KeysSavedForThisCase);
                setGridPageNumber(gridPageNumber);
                setGridPreviousData(getGridDataFromSubmittedPages(r.KeysSavedForThisCase, r.VersionedDataForThisCase));
                setFieldFormData(dataArrayToObject(r.VersionedDataForThisCase));
                setResubmitEnabled(false);
                setErrorMessage('');
            })
            .catch(e=>{
                setLoading(false);
                setErrorMessage(e.message);
            });
    }, [caseReferrence, setErrorMessage, setPagesCompleted, setGridPageNumber, setResubmitEnabled]);
    
    const showTheForm = ( 0 < submissionsRemaining ) || resubmitEnabled;

    const submitForm = useCallback(e=>{
        if (!showTheForm) {
            setResubmitEnabled(true);
        }else if(dataIsValid){

            const newfieldFormData = {...fieldFormData};

            const dataSetName = dataSetNameForPageName(pageName, gridPageNumber);
            const formDataToSend = filterDataForForm(fieldFormData, fieldDefinitions);
            const params = {
                CaseReferrence: caseReferrence,
                DataSetName: dataSetName,
                FormData: formDataToSend,
                SendDetail: true
            };

            setErrorMessage('');
            setLoading(true);
            api.transact('CaseAdditionalVersionedDataSave', params)
            .then(r => r.apiResult)
            .then(r => {
                setLoading(false);
                const gridPageNumber = getGridPageNumberFromSubmittedPages(r.KeysSavedForThisCase);
                setGridPageNumber(gridPageNumber);
                setFieldFormData(dataArrayToObject(r.VersionedDataForThisCase));
                setErrorMessage('Saved');
                setHighlightValidation(false);
                setGridPreviousData(getGridDataFromSubmittedPages(r.KeysSavedForThisCase, r.VersionedDataForThisCase));
                setResubmitEnabled(false);
                if (GRID_PAGE === pageName) {
                    setFieldFormData(getClearFormGridData(newfieldFormData, fieldDefinitions)); // clear the form as column is submitted
                }
            })
            .catch(e =>  {
                setLoading(false);
                setErrorMessage('Problem saving ' + e.message);
            });
        } else {
            setErrorMessage('Some fields are not valid');
            setHighlightValidation(true);
        }
    }, [dataIsValid, api, setHighlightValidation, setErrorMessage, 
        pageName, gridPageNumber, gridPreviousData, setGridPreviousData, fieldFormData,
        showTheForm,setFieldFormData,fieldDefinitions]);


    const buttonEnabled = (showTheForm) || (!showTheForm && !resubmitEnabled);

    // the tab names and display text
    const tabs = getTabs(caseIsCreatedHere);

    const clearStyle={width: '100%', clear: 'both', float: 'left', marginBottom: '3em'};

    return <>

        <div style={{width: '100%', clear: 'both', float: 'left', marginBottom: '3em'}} >
            <Tabs content={tabs} currentTab={pageName} onClick={e=>setPageName(e.target.name)} />
        </div>

        <div style={{width: '100%', clear: 'both', float: 'left'}}>
            <TwoSwitch test={showTheForm}>
                <>
                    <TwoSwitch test={GRID_PAGE===pageName && gridPreviousData.length}>
                        <>
                        <table style={{margin: '1em 0'}} >
                        <tbody>
                        <GridDisplay fieldDefinitions={fieldDefinitions} 
                            formFieldData={gridPreviousData} />
                        </tbody>
                        </table>
                        </>
                    </TwoSwitch>
                    <p>{getRemainingSumissionsMessage(submissionsRemaining, pageName)}</p>
                <ValidatedForm fieldDefinitions={fieldDefinitions}
                            formFieldData={fieldFormData}
                            setFormFieldData={setFieldFormData}
                            setDataIsValid={v=>null}
                            validating={highlightValidation}
                            createOptionalOverrideFieldComponent={createOptionalOverrideFieldComponent} />
                    {(!dataIsValid)? <p>Mandatory information needs to be completed</p>: null}
                </>
                <p>You have already submitted this form</p>
             </TwoSwitch>
             <ButtonHolder action={submitForm}  actionTxt={showTheForm? 'Submit result': 'Edit forms again'} 
                enabled={ buttonEnabled && !loading} 
                            cancel={cancelEdit} cancelTxt='Back to case list (without saving form)' />
            
            <LoadingIndicator loading={loading} />
            <p>{errorMessage}</p>
        </div>

    </>
        
}

const createOptionalOverrideFieldComponent = fd => {
    if ('timetextbox' === fd.controltype) {
        return props => <div>
            {/* <pre>{JSON.stringify(props, null, '\t')}</pre> */}
            {React.createElement(TimeTextBox, props)}
        </div>
    } else {
        return null;
    }
};

const getTabs = caseIsCreatedHere => {

    const createTab = {
        name:   patientDetailsForm,
        value:  'Case Details'
    };
    const mainTabs = [
        {
            name:   caseUpdateFirstPart,
            value:  'History'
        },
        {
            name:   GRID_PAGE,
            value:  'Clinical Observations'
        },
        {
            name:   caseUpdateSecondPart,
            value:  'Examination / Management / Conclusion'
        }
    ];
    return caseIsCreatedHere?
        mainTabs:
        [createTab, ...mainTabs]
}

export default CaseUpdateMainPage;
export {dataArrayToObject, getGridDataFromSubmittedPages};