
const holdingRoomsSecondPart = [
    {
        "name": "Diagnosis",
        "text": "Diagnosis",
        "enabled": "true",
        "required": false,
        "controlType": "TextBox",
        "width": 100,
        "height": 5,
        "uniqueID": 1
    },
    {
        "name": "Pathway",
        "text": "Pathway",
        "enabled": "true",
        "required": false,
        "controlType": "dropdownlist",
        "width": 100,
        Options: [
            {
                "Text": "ED",
                "Value": "ed"
            },
            {
                "Text": "Police Custody",
                "Value": "police_custody"
            },
            {
                "Text": "Prison",
                "Value": "prison"
            } ,
            {
                "Text": "L&D",
                "Value": "l_and_d"
            } ,
            {
                "Text": "Alcohol",
                "Value": "alcohol"
            } ,
            {
                "Text": "Drugs",
                "Value": "drugs"
            } ,
            {
                "Text": "Housing",
                "Value": "housing"
            },
            {
                "Text": "Employment",
                "Value": "employment"
            } ,
            {
                "Text": "Safeguarding",
                "Value": "safeguarding"
            } ,
            {
                "Text": "Veteran",
                "Value": "veteran"
            },
            {
                "Text": "Youth Offending Service",
                "Value": "youth_offending_service"
            },
            {
                "Text": "Woman�s Refuge",
                "Value": "womans_refuge"
            },
            {
                "Text": "GP",
                "Value": "gp"
            },
            {
                "Text": "Border Force",
                "Value": "border_force"
            },
            {
                "Text": "Mitie",
                "Value": "mitie"
            },
            {
                "Text": "Serco",
                "Value": "serco"
            },
            {
                "Text": "N/A",
                "Value": "N_A"
            }
        ],
        "uniqueID": 2
    },
    {
        "name": "plan",
        "text": "Plan",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300",
        "uniqueID": 3
    },
    {
        "text": "Mediaction Administered",
        "name": "mediaction_administered",
        "controlType": "dropdownlist",
        "required": false,
        "uniqueID": 4,
        "Options": [
            {
                "Text": "Med1",
                "Value": "med1"
            },
            {
                "Text": "Med2",
                "Value": "med2"
            }
        ]
    },
    {
        "name": "Other treatment/additional information",
        "text": "other_treatment_additional_information",
        "enabled": "true",
        "required": "True",
        "controlType": "TextBox",
        "width": "300",
        "uniqueID": 5
    },
    {
        "name": "Patient Sent To Hospital",
        "text": "Patient Sent To Hospital",
        "enabled": "true",
        "required": "True",
        "controlType": "CheckBox",
        "uniqueID": 6
    }
];

export default holdingRoomsSecondPart;